import axios from 'axios';
import { differenceInCalendarDays, isValid as isValidDate } from 'date-fns';
import { answerToDate } from '@/forms/helpers';

function calculateDays(start, eind, jeugdigeId) {
  const startDate = answerToDate(start);
  const endDate = answerToDate(eind);

  if (isValidDate(startDate) && isValidDate(endDate)) {
    const diff = differenceInCalendarDays(endDate, startDate);

    if (diff > -1) {
      return axios
        .get('/api/DeeltijdPleegzorgvergoeding', {
          params: {
            startdatum: start,
            einddatum: eind,
            jeugdigeId,
          },
        });
    }
  }

  return Promise.resolve(null);
}

export default calculateDays;
