import axios from 'axios';

const getInitialState = () => ({
  loading: false,
  all: [],
  intialized: false,
  error: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },

    setPleegkinderen(state, pleegkinderen) {
      state.all = pleegkinderen;
    },

    setInitialized(state, initialized) {
      state.intialized = initialized;
    },

    setError(state, error) {
      state.error = error;
    },
  },
  actions: {

    async initialize({ dispatch }, data) {
      await dispatch('getPleegkinderen', data);
    },

    async getPleegkinderen({ state, commit }, data) {
      // if (!state.intialized) {

      try {
        commit('incrementLoading', true, { root: true });
        const pleegouderId = data && data.pleegouderId;
        const response = await axios.get('/api/pleegkinderen', { params: { pleegouderId } });
        const pleegkinderen = response.data;

        commit('decrementLoading', true, { root: true });
        commit('setPleegkinderen', pleegkinderen);
        commit('setInitialized', true);
      } catch {
        commit('setError', true);
        commit('decrementLoading', true, { root: true });
        console.error('Er ging iets mis bij het laden van de pleegkinderen.');
      }
      // }
    },
  },
  getters: {},
};
