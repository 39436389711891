import { formItemModel as FormItem, validation } from 'icatt-vue-forms';

import customValidation from '@/helpers/functions/validation.js';
import {
  jaNee, BiologischeOuders, jaNeeOnbekend, woontElders,
} from '../constants';
import { shouldStop, gegevensOudersAreRequired, prefill } from '../helpers';
import { VolledigeNaam, Adres, OuderItem } from '../blokken/blokken';

const stapGezagEnBiologischeOuders = new FormItem({
  evaluate(context) {
    this.isActief = !shouldStop(context);
  },
  items: { // GezagEnBiologischeOuders
    gOWieGezag: new FormItem({
      evaluate(context) {
        prefill(this, context.form.items.vereisten.items.beslisboom.items.wieGezag);
      },
      opties: [
        { label: BiologischeOuders, value: BiologischeOuders },
        { label: 'Gecertificeerde instelling', value: 'Gecertificeerde instelling' },
        { label: 'Derde', value: 'Derde' },
      ],
    }),
    gegevensGezaghebbende: new FormItem({
      evaluate(context) {
        const { antwoord } = context.form.items.gezagEnBiologischeOuders.items.gOWieGezag;
        this.isActief = antwoord === 'Derde';
      },
      items: { // GegevensGezaghebbende
        volledigeNaam: new VolledigeNaam(),
        geboortedatum: new FormItem({
          validators: [validation.required, validation.datum, customValidation.dateInThePast],
        }),
        adres: new Adres(),
        relatieTotJeugdige: new FormItem(),
        staatAchterPlaatsing: new FormItem({
          evaluate(context) {
            prefill(this, context.form.items.vereisten.items.beslisboom.items.steunPlaatsing);
          },
          opties: jaNee,
        }),
        heeftJeugdigeContactMetDerde: new FormItem({
          opties: jaNee,
        }),
        heeftJeugdigeContactMetDerdeToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.form.items.gezagEnBiologischeOuders.items.gegevensGezaghebbende.items.heeftJeugdigeContactMetDerde;
            this.isActief = antwoord === 'Ja';
          },
        }),
      },
    }),
    gegevensOuders: new FormItem({
      items: { // GegevensOuders
        ouder1: new OuderItem(),
        isTweedeBiologischeOuderBekend: new FormItem({
          evaluate(context) {
            if (gegevensOudersAreRequired(context)) {
              this.validators = [validation.required];
            } else {
              this.validators = [];
            }
          },
          opties: [
            { value: 'Ja, deze ouder woont op het adres van de biologische ouder 1', label: 'Ja, deze ouder woont op het adres van de biologische ouder 1' },
            { value: woontElders, label: woontElders },
            { value: 'Nee, deze is overleden / niet in beeld', label: 'Nee, deze is overleden / niet in beeld' },
          ],
        }),
        ouder2: new OuderItem(true),
        staatAchterPlaatsing: new FormItem({
          evaluate(context) {
            prefill(this, context.form.items.vereisten.items.beslisboom.items.boSteunPlaatsing);

            if (gegevensOudersAreRequired(context)) {
              this.validators = [validation.required];
            } else {
              this.validators = [];
            }
          },
          opties: jaNee,
        }),
        staatAchterPlaatsingToelichting: new FormItem({
          evaluate(context) {
            if (gegevensOudersAreRequired(context)) {
              this.validators = [validation.required];
            } else {
              this.validators = [];
            }
          },
        }),
        beperking: new FormItem({
          evaluate(context) {
            prefill(this, context.form.items.vereisten.items.beslisboom.items.beperkingBioOuders);

            if (gegevensOudersAreRequired(context)) {
              this.validators = [validation.required];
            } else {
              this.validators = [];
            }
          },
          opties: jaNeeOnbekend,
        }),
        beperkingToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.form.items.gezagEnBiologischeOuders.items.gegevensOuders.items.beperking;
            this.isActief = antwoord === 'Ja';
          },
        }),
      },
    }),
  },
});

export { stapGezagEnBiologischeOuders };
