import axios from 'axios';

const getInitialState = () => ({
  current: null,
  laatsteVersie: null,
  errors: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => Object.assign(state, getInitialState()),

    setDeclaratie(state, declaratie) {
      state.current = declaratie;
    },
    setAanpassing(state, laatsteVersie) {
      state.laatsteVersie = laatsteVersie;
    },
    setErrors(state, data) {
      const { errors } = data;
      const { error } = data;

      if (errors) state.errors = errors;
      else if (error) state.errors = { algemeen: [error] };
      else state.errors = data;
    },
  },
  actions: {
    initialize({
      state, rootState, commit, dispatch,
    }) {
      dispatch('getDeclaraties');
      dispatch('getStatussen');
    },
    getDeclaratie({
      state, rootState, commit, dispatch,
    }, id) {
      commit('setDeclaratie', {});
      commit('setAanpassing', {});
      commit('incrementLoading', true, { root: true });

      axios.get(`/api/declaraties/${id}`)
        .then((response) => {
          const declaratie = response.data;
          commit('decrementLoading', true, { root: true });
          commit('setDeclaratie', declaratie.origineel);
          commit('setAanpassing', declaratie.laatsteVersie);
        })
        .catch(() => {
          commit('decrementLoading', true, { root: true });
          console.error('Er ging iets mis bij het laden van de declaratie.');
        });
    },

    goedkeuren({
      state, commit, dispatch, getters,
    }) {
      const vergoedingen = getters.getVergoedingen;
      const declaratieId = state.laatsteVersie.id;
      const { kostenplaats, createdAt } = state.laatsteVersie;

      const declaratie = {
        declaratieId,
        betaalbaarGesteld: true,
        vergoedingen,
        kostenplaats,
        createdAt,
      };

      commit('incrementLoading', true, { root: true });
      commit('setErrors', false);
      axios.post('/api/Declaraties/goedkeuren', declaratie)
        .then(() => {
          dispatch('getDeclaratie', declaratieId);
          commit('decrementLoading', true, { root: true });
        })
        .catch((error) => {
          commit('setErrors', error.response.data);
          commit('decrementLoading', true, { root: true });
        });
    },

    afkeuren({
      state, getters, commit, dispatch,
    }) {
      const vergoedingen = getters.getVergoedingen;
      const declaratieId = state.laatsteVersie.id;

      const declaratie = {
        declaratieId,
        betaalbaarGesteld: false,
        vergoedingen,
      };

      commit('incrementLoading', true, { root: true });
      commit('setErrors', false);
      axios.post('/api/Declaraties/afkeuren', declaratie)
        .then(() => {
          dispatch('getDeclaratie', declaratieId);
          commit('decrementLoading', true, { root: true });
        })
        .catch((error) => {
          commit('setErrors', error.response.data);
          commit('decrementLoading', true, { root: true });
        });
    },

    // verwijderd, maar nog niet echt weg. komt mogelijk weer terug.
    // wachten({
    //   state, commit, dispatch,
    // }) {
    //   const declaratieId = state.laatsteVersie.id;
    //   const declaratie = {
    //     declaratieId,
    //   };
    //   commit('incrementLoading', true, { root: true });
    //   commit('setErrors', false);
    //   axios.post('/api/Declaraties/wachten', declaratie)
    //     .then(() => {
    //       dispatch('getDeclaratie', declaratieId);
    //     })
    //     .catch((error) => {
    //       commit('setErrors', error.response.data);
    //     })
    //     .finally(() => {
    //       commit('decrementLoading', true, { root: true });
    //     });
    // },

  },
  getters: {
    getVergoedingen: (state) => {
      if (!state.laatsteVersie) return null;
      const {
        overigeVergoedingen, kilometerVergoedingen, ovVergoedingen, deeltijdPleegzorgVergoedingen,
      } = state.laatsteVersie;
      const list = [...overigeVergoedingen, ...kilometerVergoedingen, ...ovVergoedingen, ...deeltijdPleegzorgVergoedingen];
      return list.map((x) => {
        x.geboorteDatum = state.laatsteVersie.jeugdigeGeboortedatum;
        return x;
      });
    },
  },
};
