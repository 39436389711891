import { render, staticRenderFns } from "./EnvironmentSticker.vue?vue&type=template&id=2b4fa746&scoped=true&"
import script from "./EnvironmentSticker.vue?vue&type=script&lang=js&"
export * from "./EnvironmentSticker.vue?vue&type=script&lang=js&"
import style0 from "./EnvironmentSticker.vue?vue&type=style&index=0&id=2b4fa746&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b4fa746",
  null
  
)

export default component.exports