import axios from 'axios';
import QRCode from 'davidshimjs-qrcodejs';
import routes from '../router';

const getInitialState = () => ({
  authenticated: null,
  authorizations: [],
  sharedKey: '',
  authenticatorUri: '',
  PleegouderAccount: {
    Email: '',
  },
  LoginErrors: {},
  // RegisterErrors: '',
  selectedAccount: {
    pleegkinderen: [],
    account: {},
  },
  isRefreshing: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },
    setEmailaddress(state, email) {
      state.PleegouderAccount.Email = email;
    },
    setAuthorizations(state, authorizations) {
      state.authorizations = authorizations;
    },
    setAuthenticated(state, loggedin) {
      state.authenticated = loggedin;
    },
    setLoginresult(state, error) {
      state.LoginErrors = error.errors;
    },
    clearLoginresult(state) {
      state.LoginErrors = {};
    },
    // setRegisterResult: function (state, error) {
    //   if (error && error.errors) {
    //     state.RegisterErrors = error.errors
    //   }
    // },
    setMfaCodes(state, data) {
      state.sharedKey = data.formattedKey;
      state.authenticatorUri = data.barcodeUrl;
      const element = document.getElementById('qrCode');
      new QRCode(element, data.barcodeUrl);
    },
    clearSelectedAccountPleegkinderen(state) {
      state.selectedAccount.pleegkinderen = null;
    },
    setSelectedAccountPleegkinderen(state, data) {
      state.selectedAccount.pleegkinderen = data;
    },
    setSelectedAccount(state, data) {
      state.selectedAccount.account = data;
    },
    setSelectedAccountBlockedStatus(state, data) {
      state.selectedAccount.account.isDisabled = data.isDisabled;
    },
    setSelectedAccountTwoFactorEnabled(state, isEnabled) {
      state.selectedAccount.account.twoFactorEnabled = isEnabled;
    },
    setRefreshing(state, isRefreshing) {
      state.isRefreshing = !!isRefreshing;
    },
  },
  actions: {

    logoff({
      state, rootState, commit, dispatch,
    }) {
      commit('incrementLoading', true, { root: true });
      this.dispatch('resetAll'); // omdat het in root store zit toegankelijk via this ipv context ?!
      return axios.post('/api/Logoff')
        .then((response) => {
          commit('setAuthenticated', false);
          // window.location.reload(); // om account store obj weer leeg te maken
          commit('decrementLoading', true, { root: true });
        })
        .catch((error) => {
          console.log('logoff', error);
          commit('decrementLoading', true, { root: true });
        });
    },

    login({
      state, rootState, commit, dispatch,
    }, obj) {
      commit('clearLoginresult');
      // wrapped in a promise to enable redirect from login componentn after login request is done
      return new Promise((resolve, reject) => {
        axios.post('/api/Login', obj)
          .then((response) => {
            commit('setAuthenticated', true);
            if (typeof response.data === 'object') {
              commit('setAuthorizations', response.data);
            }
            resolve(response, state);
          })
          .catch((error) => {
            if (error.response.status === 500) {
              commit('setLoginresult', { errors: { algemeen: error.response.data } });
            } else if (error.response.status === 401) {
              const { data } = error.response;
              if (data === 0) {
                routes.push('/login/mfa/setup');
              } else if (data === 1) {
                routes.push('/login/mfa');
              }
            } else {
              commit('setLoginresult', error.response.data);
            }
            commit('setAuthorizations', []);
            reject(error);
          });
      });
    },
    // registerPleegouder: function ({ state, rootState, commit, dispatch }) {
    //   // accounts aanmaken is vooralsnog geen 'publieke' functionaliteit
    //   // nette feedback dus nog niet nodig
    //   commit('incrementLoading', true, { root: true })
    //   axios.post('/api/Register', state.PleegouderAccount)
    //     .then(response => {
    //       commit('decrementLoading', true, { root: true })
    //       console.log('registerPleegouder -ok', response)
    //       routes.push({
    //         name: 'thankYou'
    //       })
    //     })
    //     .catch(error => {
    //       commit('setRegisterResult', error.response.data)
    //       commit('decrementLoading', true, { root: true })
    //     })
    // },
    async refreshLogin({
      commit, state,
    }) {
      if (state.isRefreshing) return;
      commit('setRefreshing', true);
      const wasAuthenticated = state.authenticated;
      try {
        const response = await axios.get('/api/Login');
        commit('setAuthenticated', true);
        if (typeof response.data === 'object') {
          commit('setAuthorizations', response.data);
        }
      } catch (error) {
        commit('setAuthenticated', false);
        if (wasAuthenticated) {
          commit('resetAll', true, { root: true });
        }
      } finally {
        setTimeout(() => commit('setRefreshing', false), 15000);
      }
    },
    mfaLogin({
      state, rootState, commit, dispatch,
    }, code) {
      commit('clearLoginresult');
      // wrapped in a promise to enable redirect from login componentn after login request is done
      return new Promise((resolve, reject) => {
        axios.post(`/api/Login/LoginWithMfa?inputCode=${code}`)
          .then((response) => {
            commit('setAuthenticated', true);
            if (typeof response.data === 'object') {
              commit('setAuthorizations', response.data);
            }
            resolve(response, state);
          })
          .catch((error) => {
            commit('setLoginresult', error.response.data);
            reject(error);
          });
      });
    },

    enableApp({
      state, rootState, commit, dispatch,
    }, code) {
      commit('clearLoginresult');
      // wrapped in a promise to enable redirect from login componentn after login request is done
      return new Promise((resolve, reject) => {
        axios.post(`/api/Login/EnableAuthenticator?inputCode=${code}`)
          .then((response) => {
            if (typeof response.data === 'object') {
              commit('setAuthorizations', response.data);
            }
            resolve(response, state);
          })
          .catch((error) => {
            commit('setLoginresult', error.response.data);
            reject(error);
          });
      });
    },

    loadMfaCodes({
      state, rootState, commit, dispatch,
    }) {
      commit('incrementLoading', true, { root: true });

      axios.get('/api/Login/EnableAuthenticator')
        .then((response) => {
          commit('decrementLoading', true, { root: true });
          commit('setMfaCodes', response.data);
        })
        .catch((error) => {
          commit('decrementLoading', true, { root: true });

          //  store.commit('API_DATA_FAILURE', error)
        });
    },

    loadAccount({ commit }, id) {
      commit('clearSelectedAccountPleegkinderen');

      return Promise.all(
        [
          axios.get('/api/pleegkinderen/', { params: { pleegouderId: id } })
            .then((response) => {
              commit('setSelectedAccountPleegkinderen', response.data);
            }),

          axios.get(`/api/clienten/${id}`)
            .then((response) => {
              commit('setSelectedAccount', response.data);
            }),
        ],
      );
    },

    blockAccount({ commit }, id) {
      return axios.post('/api/blockAccount', { id })
        .then((response) => {
          commit('setSelectedAccountBlockedStatus', response.data);
        });
    },

    resendActivation({ commit }, id) {
      return axios.post('/api/register/ResendActivation', { id });
    },

    resetAuthenticatorApp: ({ commit }, userId) => axios.post(`/api/users/${userId}/twofactorauthentications/delete`)
      .then(() => {
        commit('setSelectedAccountTwoFactorEnabled', false);
      })
    ,

  },
  getters: {
    isAuthorized: (state) => (str) => state.authorizations.includes(str),
    // str is rights string
    // see Fsd\Engine\RightsEngine.cs for options
  },
};
