import axios from 'axios';

import { optie, mergeLabels } from '@/stores/helpers';

import {
  formModel as FormModel, formItemModel as FormItem, formValidator, formParser as modelParser, validation,
} from 'icatt-vue-forms';

const oudersGeinformeerd = (ctx) => {
  const oudersGeinformeerd = ctx.form.items.inleiding.items.werkenOudersMeeSub.items.oudersGeinformeerd.antwoord === 'Ja';
  const datumValidationMessages = ctx.form.items.inleiding.items.werkenOudersMeeSub.items.datumGesprek.validationMessages;
  const datumIsValide = !datumValidationMessages || !datumValidationMessages.length;
  return werkenMee(ctx) || (oudersGeinformeerd && datumIsValide);
};

const werkenMee = (ctx) => {
  const isOverdracht = ctx.form.items.inleiding.items.waarGaatHetOm.antwoord === overdrachtVoogdij;
  const oudersWerkenMee = ctx.form.items.inleiding.items.werkenOudersMee.antwoord === 'Ja';
  return isOverdracht || oudersWerkenMee;
};

const attachementValidators = [
  validation.fileSize(10),
  validation.fileTypes(['.pdf', '.doc', '.docx', '.xlsx', '.xls', '.jpg', '.jpeg', '.png', '.eml', '.msg', '.gif']),
];

const BijlageModel = () => new FormItem(bijlageType);

const bijlageType = {
  items: {
    local: new FormItem({
      validators: attachementValidators,
    }),
    server: new FormItem(),
  },
};

const rekeningGegevensDefinition = {
  items: {
    rekeningPolisnummer: new FormItem(),
    naamMaatschappij: new FormItem(),
    saldo: new FormItem({
      validators: [validation.currency],
    }),
    datumSaldo: new FormItem({
      validators: [validation.datum],
    }),
    afschrift: new BijlageModel(),
    bijlages: new FormItem({
      items: [
        new BijlageModel(),
        new BijlageModel(),
      ],
      type: bijlageType,
    }),
  },
};

const verzekeringGegevensDefinition = {
  items: {
    rekeningPolisnummer: new FormItem(),
    naamMaatschappij: new FormItem(),
    kopiePolis: new BijlageModel(),
    bijlages: new FormItem({
      items: [
        new BijlageModel(),
        new BijlageModel(),
      ],
      type: bijlageType,
    }),
  },
};

const verzoekModel = {
  achternaam: '',
  bsn: '',
  createdAt: '',
  emailJeugdzorgwerker: '',
  geboortedatum: '',
  id: '',
  status: '',
  tussenvoegsel: null,
  updatedAt: '',
  voorletters: '',
  voornaam: '',
};

const initieleStartVoogdij = 'Initiële start voogdij';
const overdrachtVoogdij = 'Overdracht voogdij door een GI';

const getInitialState = () => ({
  submitted: false,
  saved: false,
  error: false,
  errorMessage: '',
  verzoek: verzoekModel,
  formModel: new FormModel({
    items: {
      inleiding: new FormItem({
        items: {
          waarGaatHetOm: new FormItem({
            opties: [
              optie(initieleStartVoogdij),
              optie(overdrachtVoogdij),
            ],
          }),
          overdrachtVoogdijOrganisatie: new FormItem({
            evaluate(ctx) {
              this.isActief = ctx.parent.items.waarGaatHetOm.antwoord === overdrachtVoogdij;
            },
          }),
          werkenOudersMee: new FormItem({
            opties: [optie('Ja'), optie('Nee')],
            evaluate(ctx) {
              this.isActief = ctx.parent.items.waarGaatHetOm.antwoord === initieleStartVoogdij;
            },
          }),
          werkenOudersMeeSub: new FormItem({
            evaluate(ctx) {
              this.isActief = ctx.parent.items.werkenOudersMee.antwoord === 'Nee';
            },
            items: {
              toelichting: new FormItem(),
              oudersGeinformeerd: new FormItem({
                opties: [optie('Ja'), optie('Nee')],
              }),
              datumGesprek: new FormItem({
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.oudersGeinformeerd.antwoord === 'Ja';
                },
                validators: [validation.datum, validation.required],
              }),
            },
          }),
        },
      }),
      checklist: new FormItem({
        items: {
          bestaandeBankSpaarRekening: new FormItem({
            evaluate(ctx) {
              this.isActief = werkenMee(ctx);
            },
            items: {
              checked: new FormItem({
                opties: [optie('Er zijn bestaande bank- en spaarrekeningen op naam van de jeugdige')],
              }),
              gegevens: new FormItem({
                items: [],
                type: rekeningGegevensDefinition,
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.checked.antwoord.length > 0;
                },
              }),
            },
          }),
          rekeningOpasOmas: new FormItem({
            items: {
              checked: new FormItem({
                opties: [optie('Er zijn opa\'s/oma\'s met  een bank- of spaarrekening op naam van de jeugdige')],
              }),
              gegevens: new FormItem({
                items: [],
                type: rekeningGegevensDefinition,
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.checked.antwoord.length > 0;
                },
              }),
            },
          }),
          procedureSchadeVergoeding: new FormItem({
            evaluate(ctx) {
              this.isActief = werkenMee(ctx);
            },
            items: {
              checked: new FormItem({
                opties: [optie('Er loopt een procedure rondom een schadevergoeding')],
              }),
              gegevens: new FormItem({
                items: {
                  toelichting: new FormItem(),
                  bijlages: new FormItem({
                    items: [
                      new BijlageModel(),
                      new BijlageModel(),
                      new BijlageModel(),
                    ],
                    type: bijlageType,
                  }),
                },
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.checked.antwoord.length > 0;
                },
              }),
            },
          }),
          levensverzekering: new FormItem({
            evaluate(ctx) {
              this.isActief = werkenMee(ctx);
            },
            items: {
              checked: new FormItem({
                opties: [optie('De jeugdige is verzekeringsnemer / verzekerde in een levensverzekering')],
              }),
              gegevens: new FormItem({
                items: [],
                type: verzekeringGegevensDefinition,
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.checked.antwoord.length > 0;
                },
              }),
            },
          }),
          aansprakelijkheidsverzekering: new FormItem({
            evaluate(ctx) {
              this.isActief = werkenMee(ctx);
            },
            items: {
              checked: new FormItem({
                opties: [optie('De jeugdige is verzekerd middels een aansprakelijkheidsverzekering')],
              }),
              gegevens: new FormItem({
                items: [],
                type: verzekeringGegevensDefinition,
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.checked.antwoord.length > 0;
                },
              }),
            },
          }),
          roerendeZaken: new FormItem({
            evaluate(ctx) {
              this.isActief = werkenMee(ctx);
            },
            items: {
              checked: new FormItem({
                opties: [optie('De jeugdige heeft roerende zaken met een gezamenlijke waarde van meer dan €11.250')],
              }),
              gegevens: new FormItem({
                items: [],
                type: {
                  items: {
                    omschrijving: new FormItem(),
                    waarde: new FormItem({
                      validators: [validation.currency],
                    }),
                    bijlages: new FormItem({
                      items: [
                        new BijlageModel(),
                        new BijlageModel(),
                        new BijlageModel(),
                      ],
                      type: bijlageType,
                    }),
                  },
                },
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.checked.antwoord.length > 0;
                },
              }),
            },
          }),
          oudersOverleden: new FormItem({
            evaluate(ctx) {
              this.isActief = werkenMee(ctx);
            },
            items: {
              checked: new FormItem({
                opties: [optie('Één of beide ouders van de jeugdige zijn overleden')],
              }),
              gegevens: new FormItem({
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.checked.antwoord.length > 0;
                },
                items: {
                  toedracht: new FormItem({
                    items: [],
                    type: {
                      items: {
                        wie: new FormItem(),
                        wanneer: new FormItem(),
                      },
                    },
                  }),
                  nabestaandenUitkering: new FormItem({
                    opties: [optie('Ja'), optie('Nee')],
                  }),
                  nabestaandenUitkeringToelichting: new FormItem({
                    items: {
                      wie: new FormItem(),
                      rekeningnummer: new FormItem(),
                    },
                    evaluate(ctx) {
                      this.isActief = ctx.parent.items.nabestaandenUitkering.antwoord === 'Ja';
                    },
                  }),
                  wezenpensioen: new FormItem({
                    opties: [optie('Ja'), optie('Nee')],
                  }),
                  wezenpensioenToelichting: new FormItem({
                    items: {
                      wie: new FormItem(),
                      rekeningnummer: new FormItem(),
                    },
                    evaluate(ctx) {
                      this.isActief = ctx.parent.items.wezenpensioen.antwoord === 'Ja';
                    },
                  }),

                },
              }),
            },
          }),
          onverdeeldeBoedel: new FormItem({
            evaluate(ctx) {
              this.isActief = werkenMee(ctx);
            },
            items: {
              checked: new FormItem({
                opties: [optie('Er is sprake van een onverdeelde boedel')],
              }),
              gegevens: new FormItem({
                evaluate(ctx) {
                  this.isActief = ctx.parent.items.checked.antwoord.length > 0;
                },
                items: {
                  naamOverledene: new FormItem(),
                  overlijdensdatum: new FormItem({
                    validators: [validation.datum],
                  }),
                  relatieJeugdige: new FormItem(),
                  notarisJaNee: new FormItem({
                    opties: [optie('Ja'), optie('Nee')],
                  }),
                  toelichting: new FormItem(),
                  akteVanOverlijden: new BijlageModel(),
                  bijlages: new FormItem({
                    items: [
                      new BijlageModel(),
                      new BijlageModel(),
                    ],
                    type: bijlageType,
                  }),
                },
              }),
            },
          }),
        },
      }),
      gesprekken: new FormItem({
        items: [
        ],
        type: {
          items: {
            datum: new FormItem({
              validators: [validation.datum],
            }),
            naamContact: new FormItem(),
            toelichting: new FormItem(),
          },
        },
      }),
    },
  }),
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    POPULATE_FORM_MODEL(state, formModel) {
      modelParser.injectDataToModel(formModel, state.formModel);
    },
    MERGE_LABELS(state, labels) {
      mergeLabels(labels, state.formModel);
    },
    RESET_STATE(state) {
      Object.assign(state, getInitialState());
    },
    SET_ERROR(state, error, message) {
      state.error = error;
      state.errorMessage = message;
    },
    SET_SAVED(state, saved) {
      state.saved = saved;
    },
    SET_SUBMITTED(state, submitted) {
      state.submitted = submitted;
    },
    SET_VERZOEK(state, verzoek) {
      state.verzoek = verzoek;
    },
  },
  actions: {
    fetchChecklistData({ commit }, id) {
      commit('incrementLoading', true, { root: true });

      axios.get(`/api/vermogensbeheer/verzoek/${id}`)
        .then((res) => {
          if (res.data) {
            commit('SET_VERZOEK', res.data);
          }
        })
        .catch((error) => {
          console.error(error);
          commit('SET_ERROR', true);
        })
        .then(() => {
          commit('decrementLoading', true, { root: true });
        });
    },
    fetchFormData({ commit }, id) {
      commit('incrementLoading', true, { root: true });

      axios.get(`/api/vermogensbeheer/checklist/${id}`)
        .then((res) => {
          if (res.data) {
            commit('POPULATE_FORM_MODEL', res.data);
          }
        })
        .catch((error) => {
          console.error(error);
          commit('SET_ERROR', true);
        })
        .then(() => {
          commit('decrementLoading', true, { root: true });
        });
    },
    fetchLabels({ commit }) {
      commit('incrementLoading', true, { root: true });

      axios.get('/api/vermogensbeheer/checklist/labels')
        .then((res) => {
          if (res.data) {
            commit('MERGE_LABELS', res.data);
          }
        })
        .catch((error) => {
          console.error(error);
          commit('SET_ERROR', true);
        })
        .then(() => {
          commit('decrementLoading', true, { root: true });
        });
    },
    initialize({ commit, dispatch }, id) {
      commit('RESET_STATE');
      dispatch('fetchLabels');
      dispatch('fetchChecklistData', id);
      dispatch('fetchFormData', id);
    },
    submitForm({ commit, state }, payload) {
      const form = state.formModel;

      formValidator(form);

      modelParser.submit(form, (data) => {
        commit('incrementLoading', true, { root: true });

        axios.post(`/api/vermogensbeheer/checklist/${payload.type}/${payload.id}`, data)
          .then(() => {
            (payload.type === 'indienen' || payload.type === 'afronden')
              ? commit('SET_SUBMITTED', true)
              : commit('SET_SAVED', true);

            commit('decrementLoading', true, { root: true });
          })
          .catch(() => {
            commit('SET_ERROR', true);
            commit('decrementLoading', true, { root: true });
          });
      });
    },
  },
  getters: {
    oudersGeinformeerd: (state) => {
      const context = { form: state.formModel };
      return oudersGeinformeerd(context);
    },
  },
};
