import { formItemModel as FormItem, validation } from 'icatt-vue-forms';

import customValidation from '@/helpers/functions/validation.js';
import { jaNee, jaNeeOnbekend } from '../constants';
import { prefill, shouldStop } from '../helpers';

const stapInformatieJeugdige = new FormItem({
  shouldClearForClone: true,
  evaluate(context) {
    this.isActief = !shouldStop(context);
  },
  items: { // InformatieJeugdige
    beperkingVJeugdige: new FormItem({
      opties: jaNeeOnbekend,
      validators: [validation.required],
      evaluate(context) {
        prefill(this, context.form.items.vereisten.items.beslisboom.items.beperkingVJeugdige);
      },
    }),
    iqVragen: new FormItem({
      evaluate(context) {
        const { antwoord } = context.form.items.informatieJeugdige.items.beperkingVJeugdige;
        const matches = ['Ja', 'Nee'];
        this.isActief = matches.includes(antwoord);
      },
      items: {
        iqJeugdige: new FormItem({
        }),
        iqVastgesteldWanneer: new FormItem({
          validators: [validation.datum],
        }),
        iqVastgesteldDoorWie: new FormItem({
        }),
      },
    }),
    beperkingLJeugdige: new FormItem({
      opties: jaNeeOnbekend,
      validators: [validation.required],
      evaluate(context) {
        prefill(this, context.form.items.vereisten.items.beslisboom.items.beperkingLJeugdige);
      },
    }),
    beperkingJeugdigeNamelijk: new FormItem({
      validators: [validation.required],
      evaluate(context) {
        const { antwoord } = context.form.items.informatieJeugdige.items.beperkingLJeugdige;

        this.isActief = antwoord === 'Ja';
      },
    }),
    jgDiagnose: new FormItem({
      validators: [validation.required],
      opties: jaNeeOnbekend,
    }),
    jgDiagnoseVragen: new FormItem({
      evaluate(context) {
        const { antwoord } = context.form.items.informatieJeugdige.items.jgDiagnose;
        this.isActief = antwoord === 'Ja';
      },
      items: {
        jgDiagnoseWelke: new FormItem({
          validators: [validation.required],
        }),
        jgDiagnoseDatum: new FormItem({
          validators: [validation.required, validation.datum],
        }),
        jgDiagnoseGesteldDoor: new FormItem({
          validators: [validation.required],
        }),
      },
    }),
    jgOntwikkelingen: new FormItem({
      opties: jaNeeOnbekend,
    }),
    jgOntwikkelingenWelke: new FormItem({
      evaluate(context) {
        const { antwoord } = context.form.items.informatieJeugdige.items.jgOntwikkelingen;
        this.isActief = antwoord === 'Ja';
      },
    }),
    schoolDagbesteding: new FormItem({
      validators: [validation.required],
    }),
    welkevrijetijdsbesteding: new FormItem({
      validators: [validation.required],
    }),
    juridischeMaatregel: new FormItem({
      opties: jaNee,
      evaluate(context) {
        prefill(this, context.form.items.vereisten.items.beslisboom.items.juridischeMaatregel);
      },
      validators: [validation.required],
    }),
    maatregel: new FormItem({
      validators: [validation.required],
      opties: [
        { label: '(Voorlopige) OTS', value: '(Voorlopige) OTS' },
        { label: 'Voorlopige Voogdij', value: 'Voorlopige Voogdij' },
        { label: 'Voogdij', value: 'Voogdij' },
        { label: 'Reclassering', value: 'Reclassering' },
      ],
      evaluate(context) {
        const { antwoord } = context.form.items.informatieJeugdige.items.juridischeMaatregel;

        this.isActief = antwoord === 'Ja';
      },
    }),
    otsLooptTot: new FormItem({
      evaluate(context) {
        const { antwoord, isActief } = context.form.items.informatieJeugdige.items.maatregel;
        this.isActief = isActief && antwoord.includes('(Voorlopige) OTS');
      },
      validators: [validation.datum, customValidation.dateInTheFuture],
    }),
    voorlopigeVoogdijLooptTot: new FormItem({
      evaluate(context) {
        const { antwoord, isActief } = context.form.items.informatieJeugdige.items.maatregel;
        this.isActief = isActief && antwoord.includes('Voorlopige Voogdij');
      },
      validators: [validation.datum, customValidation.dateInTheFuture],
    }),
    voogdijLooptTot: new FormItem({
      evaluate(context) {
        const { antwoord, isActief } = context.form.items.informatieJeugdige.items.maatregel;
        this.isActief = isActief && antwoord.includes('Voogdij');
      },
      validators: [validation.datum, customValidation.dateInTheFuture],
    }),
    machtigingUithuisplaatsingLooptTot: new FormItem({
      evaluate(context) {
        const { antwoord, isActief } = context.form.items.informatieJeugdige.items.maatregel;
        this.isActief = isActief && antwoord.includes('Machtiging Uithuisplaatsing');
      },
      validators: [validation.datum, customValidation.dateInTheFuture],
    }),
    reclasseringLooptTot: new FormItem({
      evaluate(context) {
        const { antwoord, isActief } = context.form.items.informatieJeugdige.items.maatregel;
        this.isActief = isActief && antwoord.includes('Reclassering');
      },
      validators: [validation.datum, customValidation.dateInTheFuture],
    }),
    waarIsDanSprakeVan: new FormItem({
      evaluate(context) {
        const { antwoord } = context.form.items.informatieJeugdige.items.juridischeMaatregel;
        this.isActief = antwoord === 'Nee';
      },
      opties: [
        { label: 'Vrijwillig kader', value: 'Vrijwillig kader' },
        { label: 'Drang', value: 'Drang' },
      ],
      validators: [validation.required],
    }),
  },
});

export { stapInformatieJeugdige };
