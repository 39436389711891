import { formItemModel as FormItem, validation } from 'icatt-vue-forms';

import customValidation from '@/helpers/functions/validation.js';
import {
  NetwerkRoute, CrisisRoute, OvernameRoute, BestandRoute, fileTypes, GezinshuisRoute,
} from '../constants';
import { shouldStop, isInRoute } from '../helpers';
import { optie } from '../../helpers';

const createCustomBijlage = () => new FormItem({
  validators: [
    validation.fileSize(10),
    validation.fileTypes(fileTypes),
  ],
});

const stapBijlagenEnAfronden = new FormItem({
  shouldClearForClone: true,
  evaluate(context) {
    this.isActief = !shouldStop(context);
  },
  items: { // BijlagenEnAfronden
    bijlagenCrisis: new FormItem({
      evaluate(context) {
        this.isActief = isInRoute(context, CrisisRoute);
      },
      items: [createCustomBijlage()],
      add() {
        this.items.push(createCustomBijlage());
      },
      validators: [(value, context) => (context.self.items.length > 10 ? 'U kunt maximaal 10 bestanden toevoegen' : null)],
    }),
    bijlagenAndereRoutes: new FormItem({
      evaluate(context) {
        this.isActief = isInRoute(context, [BestandRoute, NetwerkRoute, OvernameRoute, GezinshuisRoute]);
      },
      items: { // BijlagenAndereRoutes
        bepalingJeugdhulp: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, [BestandRoute, NetwerkRoute, GezinshuisRoute]);
          },
          validators: [
            validation.required,
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        planVanHetKind: new FormItem({
          validators: [
            validation.required,
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        planVanAanpak: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, [OvernameRoute]);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        risicoTaxatieLijstPleegouders: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, [OvernameRoute, NetwerkRoute]);
          },
          validators: [
            validation.required,
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        rapportagePleeggezin: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, OvernameRoute);
          },
          validators: [
            validation.required,
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        contactjournaalPleeggezin: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, OvernameRoute);
          },
          validators: [
            validation.required,
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        rapportageJeugdige: new FormItem({
          evaluate(context) {
            this.isActief = !isInRoute(context, NetwerkRoute);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        raadsRapportage: new FormItem({
          evaluate(context) {
            this.isActief = !isInRoute(context, NetwerkRoute);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        veiligheidlijstJeugdige: new FormItem({
          evaluate(context) {
            this.isActief = !isInRoute(context, NetwerkRoute);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        genogram: new FormItem({
          evaluate(context) {
            this.isActief = !isInRoute(context, NetwerkRoute);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        screeningsverslag: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, [OvernameRoute]);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        pleegouderPlan: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, [OvernameRoute]);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        bepalingDagbesteding: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, [BestandRoute, GezinshuisRoute]);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        bepalingLogeren: new FormItem({
          evaluate(context) {
            this.isActief = isInRoute(context, [BestandRoute, GezinshuisRoute]);
          },
          validators: [
            validation.fileSize(10),
            validation.fileTypes(fileTypes),
          ],
        }),
        // hack voor bug in dotnet core :-(
        dummyNietVerwijderen: new FormItem({
          isActief: true,
          isFile: true,
        }),
      },
    }),
    verklaarGerechtigd: new FormItem({
      validators: [validation.required],
      opties: [
        optie('Ik verklaar dat ik gerechtigd ben om deze aanvraag in te dienen. Ik heb alle informatie naar waarheid ingevuld.'),
      ],
    }),
  },
});

export { stapBijlagenEnAfronden };
