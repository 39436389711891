import axios from 'axios';

let id = 0;
const getInitialState = () => ({
  alerts: [],
  isRefreshing: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },
    setAlerts(state, alerts) {
      state.alerts = Array.isArray(alerts) ? alerts : [];
    },
    setRefreshing(state, isRefreshing) {
      state.isRefreshing = isRefreshing;
    },
  },

  actions: {
    refresh({ commit, state }) {
      if (state.isRefreshing) return;
      commit('setRefreshing', true);
      axios.get(`/api/alertservice/v1.0/useralerts?${id += 1}`)
        .then((response) => {
          commit('setAlerts', response.data);
        })
        .finally(() => {
          setTimeout(() => commit('setRefreshing', false), 15000);
        });
    },
    async setSeen(_, alert) {
      const d = new Date();
      const { data } = await axios.patch(`/api/alertservice/v1.0/useralerts/${alert.id}`, [
        {
          op: 'add',
          path: 'dateSeen',
          value: d,
        },
      ], {
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      });
      alert.dateSeen = data.dateSeen;
    },
  },
  getters: {},
};
