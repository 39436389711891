import { doForItems, doRecursive } from '../helpers';
import {
  BiologischeOuders, NetwerkRoute, OvernameRoute, CrisisRoute, BestandRoute, GezinshuisRoute, Nee,
} from './constants';

const gezagStop = (context) => {
  const { juridischeMaatregel } = context.form.items.vereisten.items.beslisboom.items;

  return !context.form.isClone
    && geenSteun(context)
    && juridischeMaatregel.isActief
    && juridischeMaatregel.antwoord === Nee;
};

const geenSteun = (context) => {
  const { steunPlaatsing, boSteunPlaatsing } = context.form.items.vereisten.items.beslisboom.items;

  return (steunPlaatsing.isActief && steunPlaatsing.antwoord === Nee)
    || (boSteunPlaatsing.isActief && boSteunPlaatsing.antwoord === Nee);
};

const beperkingStop = (context) => {
  const {
    beperkingVJeugdige, beperkingLJeugdige, beperkingBioOuders, beperkingPleegouders,
  } = context.form.items.vereisten.items.beslisboom.items;

  const algemeneRegel = [beperkingVJeugdige,
    beperkingLJeugdige,
    beperkingBioOuders].every((x) => x.antwoord === Nee);

  const bestandEnGezin = isInRoute(context, [BestandRoute, GezinshuisRoute]);
  const netwerkEnOverName = isInRoute(context, [NetwerkRoute, OvernameRoute]) && beperkingPleegouders.antwoord === Nee;

  const specifiekeRegel = bestandEnGezin || netwerkEnOverName;

  return !context.form.isClone && algemeneRegel && specifiekeRegel;
};

const shouldStop = (context) => beperkingStop(context) || gezagStop(context);

const prefill = (vm, { antwoord, isActief }) => {
  const isIngevuld = antwoord.length > 0;

  if (isIngevuld && isActief) {
    vm.antwoord = antwoord;
    vm.isReadOnly = true;
  } else {
    vm.isReadOnly = false;
  }
};

const getOpvoederText = (context) => {
  if (isInRoute(context, NetwerkRoute)) {
    return 'netwerkpleegouder';
  }
  if (isInRoute(context, OvernameRoute)) {
    return 'pleegouder';
  }
  if (isInRoute(context, CrisisRoute)) {
    return 'opvoeder';
  }
  return 'opvoeder / pleegouder';
};

const getOpvoedersText = (context) => {
  if (isInRoute(context, NetwerkRoute)) {
    return 'netwerkpleegouders';
  }
  if (isInRoute(context, OvernameRoute)) {
    return 'pleegouders';
  }
  if (isInRoute(context, CrisisRoute)) {
    return 'opvoeders';
  }
  return 'opvoeders / pleegouders';
};

const gegevensOudersAreRequired = (context) => context.form.items.gezagEnBiologischeOuders.items.gOWieGezag.antwoord === BiologischeOuders;

const isTweedeOuderBekend = (context) => context.form.items.gezagEnBiologischeOuders.items.gegevensOuders.items.isTweedeBiologischeOuderBekend;

const isInRoute = (context, route) => {
  if (Array.isArray(route)) return route.some((x) => isInRoute(context, x));
  const currentRoute = context.form.items.vereisten.items.route.antwoord;
  if (currentRoute) return currentRoute.toLowerCase() === route.toLowerCase();
  return false;
};

const clearForClone = (formItem) => {
  if (!formItem) return;

  if (formItem.shouldClearForClone) {
    doRecursive(formItem, clear);
  } else {
    doForItems(formItem.items, clearForClone);
  }
};

const clear = (formItem) => {
  formItem.antwoord = '';
};

export {
  isInRoute, gezagStop, geenSteun, shouldStop, prefill, beperkingStop, getOpvoederText,
  getOpvoedersText, isTweedeOuderBekend, gegevensOudersAreRequired, clearForClone,
};
