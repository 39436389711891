import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import IcattVueForms from 'icatt-vue-forms';
import Toasted from 'vue-toasted';

import Alerts from 'alert-api-client';

import { library, config } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle, faChevronLeft, faCircleNotch, faPaperclip, faTrash, faUserEdit, faInfo, faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import euroFormat from '@/helpers/filters/euro-format';
import decimalFormat from '@/helpers/filters/decimal-format';
import store from './store';
import router from './router';
import App from './App.vue';

Vue.use(euroFormat);
Vue.use(decimalFormat);

config.autoAddCss = false;
library.add(
  faInfo,
  faChevronLeft,
  faCheckCircle,
  faCircle,
  faCircleNotch,
  faFilePdf,
  faPaperclip,
  faTrash,
  faUserEdit,
  faTimesCircle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Toasted, { duration: 3000 });

Vue.use(IcattVueForms);

// de standaard errorhandler is ongewenst. overrule werkt niet door een bug in de package
Vue.use(Alerts, {
  domain: '/api/alertservice',
  otherErrorHandler: () => { },
});

Vue.config.productionTip = false;

/** @typedef {import('vue-router').Route} Route */

/**
 *
 * @param {Route} to
 * @returns {Route}
 */
const redirectToLogin = (to) => {
  const redirectpath = to.params.redirect ? to.params.redirect : to.path;
  return { path: '/login', query: { redirect: redirectpath } };
};

/**
 *
 * @param {Route} to
 * @returns {string | false}
 */
const guardUnauthorized = (to) => {
  const isAuthorized = store.getters['account/isAuthorized'];
  const hasUnauthorized = to.matched.some((record) => record && record.meta && record.meta.right && !isAuthorized(record.meta.right));
  return hasUnauthorized && 'unauthorized';
};

/**
 *
 * @param {Route} to
 * @returns {Route | false}
 */
const guardUnauthenticated = (to) => {
  const isAuthenticated = store.state.account.authenticated;
  const shouldAuthenticate = !isAuthenticated && to.matched.some((record) => !record.meta || !record.meta.public);
  return shouldAuthenticate && redirectToLogin(to);
};

const refreshLogin = () => store.dispatch('account/refreshLogin');

router.beforeEach(async (to, _, next) => {
  await refreshLogin();
  const route = guardUnauthenticated(to) || guardUnauthorized(to);
  if (route) {
    next(route);
  } else {
    next();
  }
});

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function handleClickOutside(event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

const VerwerktGevoeligeGegevens = 'VerwerktGevoeligeGegevens';

new Vue({
  router,
  store,
  computed: {
    ...mapGetters('account', ['isAuthorized']),
    ...mapState('account', ['authorizations']),
  },
  watch: {
    authorizations: {
      immediate: true,
      handler(newValue, oldValue) {
        const oldHasSensitive = Array.isArray(oldValue) && oldValue.includes(VerwerktGevoeligeGegevens);
        const newHasSensitive = Array.isArray(newValue) && newValue.includes(VerwerktGevoeligeGegevens);

        if (!oldHasSensitive && newHasSensitive) {
          this.$toasted.show(
            'Let op, in deze applicatie wordt persoonlijke gezondheidsinformatie verwerkt. <br>Deze moet vertrouwelijk behandeld worden.',
            { duration: null, type: 'error', action: { text: 'sluiten', onClick(_, t) { t.goAway(0); } } },
          );
        }
      },
    },
  },
  render: (h) => h(App),
}).$mount('#app');
