import { optie } from '../helpers';

const BestandRoute = 'Bestandspleegzorg';
const NetwerkRoute = 'Netwerkpleegzorg';
const OvernameRoute = 'Overname';
const CrisisRoute = 'Crisispleegzorg';
const GezinshuisRoute = 'Gezinshuis';

const Ja = 'Ja';
const Nee = 'Nee';
const Onbekend = 'Onbekend';

const Derde = 'Derde';

const Jongen = 'Jongen';
const Meisje = 'Meisje';

const jongenMeisje = [
  optie(Jongen),
  optie(Meisje),
];

const jaNee = [
  optie(Ja),
  optie(Nee),
];

const jaNeeOnbekend = [
  ...jaNee,
  optie(Onbekend),
];

const BiologischeOuders = 'Biologische ouder(s)';

const jeugdigeWoontBijOuders = 'Bij zijn / haar ouders';
const jeugdigeWoontInNetwerk = 'In zijn of haar netwerk';
const bijAndereOpvoeders = 'Bij andere opvoeders';

const woontElders = 'Ja, deze ouder woont elders';
const inEenInstelling = 'In een instelling';
const anders = 'Anders';

const fileTypes = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.jpg', '.jpeg', '.png', '.bmp'];

const Voltijd = 'Voltijd';
const Deeltijd = 'Deeltijd';

const VoltijdDeeltijd = [optie(Voltijd), optie(Deeltijd)];

const ouderIsDakloos = 'Ouder is dakloos';

export {
  Ja,
  Nee,
  Onbekend,
  Derde,
  jaNee,
  jaNeeOnbekend,
  BiologischeOuders,
  jeugdigeWoontBijOuders,
  jeugdigeWoontInNetwerk,
  BestandRoute,
  NetwerkRoute,
  OvernameRoute,
  CrisisRoute,
  GezinshuisRoute,
  woontElders,
  inEenInstelling,
  anders,
  bijAndereOpvoeders,
  fileTypes,
  Voltijd,
  Deeltijd,
  VoltijdDeeltijd,
  Jongen,
  Meisje,
  jongenMeisje,
  ouderIsDakloos,
};
