import axios from 'axios';

import { formModel as FormModel, formValidator, formParser as modelParser } from 'icatt-vue-forms';

import {
  isInRoute, gezagStop, shouldStop, beperkingStop, getOpvoederText, getOpvoedersText, clearForClone,
} from './aanmeldenjeugdige/helpers';
import { mergeLabels } from './helpers';

import { stapVereisten } from './aanmeldenjeugdige/stappen/vereisten';
import { stapAlgemeneGegevens } from './aanmeldenjeugdige/stappen/algemeneGegevens';
import { stapInformatieJeugdige } from './aanmeldenjeugdige/stappen/informatieJeugdige';
import { stapGezagEnBiologischeOuders } from './aanmeldenjeugdige/stappen/gezagEnBiologischeOuders';
import { stapOverigeGegevens } from './aanmeldenjeugdige/stappen/overigeGegevens';
import { stapBijlagenEnAfronden } from './aanmeldenjeugdige/stappen/bijlagenEnAfronden';

const getInitialState = () => ({
  submitted: false,
  error: false,
  errorMessage: '',
  labels: {},
  formModel: new FormModel({
    items: {
      vereisten: stapVereisten,
      algemeneGegevens: stapAlgemeneGegevens,
      informatieJeugdige: stapInformatieJeugdige,
      gezagEnBiologischeOuders: stapGezagEnBiologischeOuders,
      overigeInformatie: stapOverigeGegevens,
      bijlagenEnAfronden: stapBijlagenEnAfronden,
    },
  }),
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },
    setError(state, error, message) {
      state.error = error;
      state.errorMessage = message;
    },

    setSubmitted(state, submitted) {
      state.submitted = submitted;
    },

    setLabels(state, labels) {
      state.labels = labels;
      mergeLabels(labels, state.formModel);

      // enable deze om c# class te genereren obv het model
      // axios.post('/api/generator', state.formModel)
    },

    setIsClone(state, val) {
      state.formModel.isClone = val;
    },

    setPreviousChild(state) {
      const { voornamen, tussenvoegsel, achternaam } = state.formModel.items.algemeneGegevens.items.gegevensJeugdige.items.volledigeNaam.items;
      const volledigeNaam = [voornamen, tussenvoegsel, achternaam].map((q) => q.antwoord).filter((a) => a).join(' ');

      state.formModel.items.vereisten.items.vorigeJeugdige.antwoord = volledigeNaam;
    },

    clearQuestionsForClone(state) {
      clearForClone(state.formModel);
    },

  },

  actions: {
    initialize({ commit }) {
      commit('incrementLoading', true, { root: true });
      axios.get('/api/aanmeldingjeugdige/GetLabels')
        .then((response) => {
          commit('setLabels', response.data);
          commit('decrementLoading', true, { root: true });
        })
        .catch((e) => {
          console.error('error in getLabels', e);
          commit('setError', true);
          commit('decrementLoading', true, { root: true });
        });
    },

    submit({ commit, state }) {
      const form = state.formModel;

      formValidator(form, true);

      modelParser.submit(form, (data) => {
        commit('incrementLoading', true, { root: true });

        axios.post('/api/AanmeldingJeugdige', data)
          .then(() => {
            commit('setSubmitted', true);
            commit('decrementLoading', true, { root: true });
          })
          .catch(() => {
            commit('setError', true);
            commit('decrementLoading', true, { root: true });
          });
      });
    },

    startClone({ commit }) {
      commit('setIsClone', true);
      commit('setPreviousChild', true);
      commit('clearQuestionsForClone');
      commit('setSubmitted', false);
    },
  },

  getters: {
    isInRoute: (state) => (route) => {
      const context = { form: state.formModel };
      return isInRoute(context, route);
    },
    opvoederText: (state) => {
      const context = { form: state.formModel };
      return getOpvoederText(context);
    },
    opvoedersText: (state) => {
      const context = { form: state.formModel };
      return getOpvoedersText(context);
    },
    beperkingStop: (state) => {
      const context = { form: state.formModel };
      return beperkingStop(context);
    },
    gezagStop: (state) => {
      const context = { form: state.formModel };
      return gezagStop(context);
    },
    shouldStop: (state) => {
      const context = { form: state.formModel };
      return shouldStop(context);
    },
  },
};
