import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

const getInitialState = () => ({
  submitted: false,
  error: false,
  errorMessage: '',
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },
    setError(state, error, message) {
      state.error = error;
      state.errorMessage = message;
    },

    setSubmitted(state, submitted) {
      state.submitted = submitted;
    },

  },

  actions: {

    sendEmail({
      state, rootState, commit, dispatch,
    }, obj) {
      commit('incrementLoading', true, { root: true });
      axios.post('/api/ForgotPassword', obj)
        .then((response) => {
          commit('decrementLoading', true, { root: true });
          commit('setSubmitted', true);
        })
        .catch((error) => {
          console.log('forgotpassword', error);
          commit('decrementLoading', true, { root: true });
          commit('setError', true, error.response.data);
        });
    },

  },
  getters: {},
};
