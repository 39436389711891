import axios from 'axios';
import { formParser as modelParser } from 'icatt-vue-forms';
import routes from '../router';

const getInitialState = () => ({
  message: '',
  success: false,
  loading: true,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },

    setMessage(state, message) {
      state.message = message;
    },

  },

  actions: {
    sendCode({
      state, rootState, commit, dispatch,
    }, form) {
      modelParser.submit(form, (data) => {
        commit('incrementLoading', true, { root: true });

        axios.post('/api/register/confirmaccount', data)
          .then((response) => {
            dispatch('account/login', {
              email: response.data,
              password: form.items.password.antwoord,
            }, {
              root: true,
            }).then(() => routes.push('/'));

            commit('decrementLoading', true, { root: true });
          })
          .catch((error) => {
            commit('decrementLoading', true, { root: true });

            const { errors } = error.response.data;

            if (errors) {
              let messages = [];

              for (const key in errors) {
                if (Object.prototype.hasOwnProperty(errors, key)) {
                  messages = [...messages, ...errors[key]];
                }
              }

              commit('setMessage', messages.join());
            } else {
              commit('setMessage', 'Er is iets misgegaan. Neem contact op met uw contactpersoon.');
            }
          });
      });
    },

  },
  getters: {},
};
