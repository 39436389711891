/* eslint-disable no-param-reassign */
import axios from 'axios';

import {
  formModel as FormModel, mergeSchema, validation, formParser as modelParser,
} from 'icatt-vue-forms';
import {
  isInRoute, vIBDoelgroep, verblijfplaatsInstelling, isRequiredPleegouderEnPleegkind, isBiologischeOuder2ZelfdeAdres,
} from './opvoedondersteuning/helpers';

import {
  PPORoute, VIBRoute,
  VIBDoelgroepPleegouderEnPleegkind,
  VIBDoelgroepOuderEnKind, OptieAnders, Ja, Nee, GezinshuisPlaats, InstellingPlaats, AndersPlaats, PleeggezinPlaats,
} from './opvoedondersteuning/constants';

import schema from './opvoedondersteuning/opvoedondersteuning.schema.json';

const oudersIsRequired = (context) => {
  if (isInRoute(context, [PPORoute])) return true;
  const { wieVIB } = context.form.items.vereisten.items.vIBAanvraag.items;
  return wieVIB.isActief && wieVIB.antwoord === VIBDoelgroepOuderEnKind;
};

const getInitialState = () => {
  const formModel = new FormModel({

  });

  const fileTypes = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.jpg', '.jpeg', '.png', '.bmp'];

  mergeSchema(formModel, schema);

  // -----dependencies
  formModel.items.vereisten.items.vIBAanvraag.evaluate = (context) => {
    context.self.isActief = isInRoute(context, [VIBRoute]);
  };
  formModel.items.vereisten.items.vIBAanvraag.items.typeVIBPleeg.evaluate = (context) => {
    context.self.isActief = isInRoute(context, [VIBRoute])
      && vIBDoelgroep(context) === VIBDoelgroepPleegouderEnPleegkind;
  };
  formModel.items.vereisten.items.vIBAanvraag.items.typeVIBPleegAnders.evaluate = (context) => {
    const { antwoord, isActief } = context.group.items.typeVIBPleeg;

    context.self.isActief = isActief && antwoord === OptieAnders && isInRoute(context, [VIBRoute]);
  };
  formModel.items.vereisten.items.vIBAanvraag.items.typeVIBOuderKind.evaluate = (context) => {
    context.self.isActief = isInRoute(context, [VIBRoute])
      && vIBDoelgroep(context) === VIBDoelgroepOuderEnKind;
  };
  formModel.items.vereisten.items.vIBAanvraag.items.typeVIBOuderKindAnders.evaluate = (context) => {
    const { antwoord, isActief } = context.group.items.typeVIBOuderKind;

    context.self.isActief = isActief && antwoord === OptieAnders && isInRoute(context, [VIBRoute]);
  };

  formModel.items.gegevensClient.items.pleegOfGezinshuisouder.evaluate = ({ self, form }) => {
    const { antwoord } = form.items.gegevensClient.items.jeugdige.items.clientVerblijf;
    self.isActief = !!antwoord && [PleeggezinPlaats, GezinshuisPlaats].includes(antwoord);
  };

  formModel.items.gegevensClient.items.pleegOfGezinshuisouder.items.pleegOfGezinshuisouder1.items.voornamen.evaluate = (context) => {
    context.self.validators = isRequiredPleegouderEnPleegkind(context) ? [validation.required] : [];
  };
  formModel.items.gegevensClient.items.pleegOfGezinshuisouder.items.pleegOfGezinshuisouder1.items.achternaam.evaluate = (context) => {
    context.self.validators = isRequiredPleegouderEnPleegkind(context) ? [validation.required] : [];
  };
  formModel.items.gegevensClient.items.pleegOfGezinshuisouder.items.pleegOfGezinshuisouder2.evaluate = (context) => {
    context.self.isActief = context.form.items.gegevensClient.items.pleegOfGezinshuisouder.items.tweedePleegouder.antwoord === Ja;
  };

  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder1.items.achternaam.evaluate = (context) => {
    context.self.validators = oudersIsRequired(context)
      ? [validation.required]
      : [];
  };
  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder1.items.voornamen.evaluate = (context) => {
    context.self.validators = oudersIsRequired(context)
      ? [validation.required]
      : [];
  };

  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.evaluate = (context) => {
    context.self.isActief = context.form.items.gegevensClient.items.biologischeOuder.items.pPOBeideOuders.antwoord === Ja;
  };
  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.items.straat.evaluate = (context) => {
    context.self.isActief = isBiologischeOuder2ZelfdeAdres(context);
  };
  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.items.huisnummer.evaluate = (context) => {
    context.self.isActief = isBiologischeOuder2ZelfdeAdres(context);
  };
  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.items.toevoeging.evaluate = (context) => {
    context.self.isActief = isBiologischeOuder2ZelfdeAdres(context);
  };
  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.items.postcode.evaluate = (context) => {
    context.self.isActief = isBiologischeOuder2ZelfdeAdres(context);
  };
  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.items.plaats.evaluate = (context) => {
    context.self.isActief = isBiologischeOuder2ZelfdeAdres(context);
  };
  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.items.telefoonnummer.evaluate = (context) => {
    context.self.isActief = isBiologischeOuder2ZelfdeAdres(context);
  };
  formModel.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.items.emailadres.evaluate = (context) => {
    context.self.isActief = isBiologischeOuder2ZelfdeAdres(context);
  };

  formModel.items.huidigeSituatie.items.hulpvraagBesproken.evaluate = (context) => {
    context.self.isActief = context.form.items.huidigeSituatie.items.aanvraagOuder.antwoord === Ja;
  };
  formModel.items.huidigeSituatie.items.ouderTegenoverHulpvraag.evaluate = (context) => {
    const { antwoord, isActief } = context.form.items.huidigeSituatie.items.hulpvraagBesproken;
    context.self.isActief = isActief && antwoord === Ja;
  };
  formModel.items.huidigeSituatie.items.redenNietMetOuderBesproken.evaluate = (context) => {
    const { antwoord, isActief } = context.form.items.huidigeSituatie.items.hulpvraagBesproken;
    context.self.isActief = isActief && antwoord === Nee;
  };

  formModel.items.bestandenEnAfronden.items.gezinsplan.evaluate = (context) => {
    context.self.validators = isInRoute(context, [PPORoute]) ? [validation.required] : [];
    context.self.validators.push(validation.fileSize(10));
    context.self.validators.push(validation.fileTypes(fileTypes));
  };

  formModel.items.bestandenEnAfronden.items.raadsrapportage.evaluate = (context) => {
    context.self.validators = isInRoute(context, [PPORoute]) ? [validation.required] : [];
    context.self.validators.push(validation.fileSize(10));
    context.self.validators.push(validation.fileTypes(fileTypes));
  };

  formModel.items.bestandenEnAfronden.items.veiligheidslijst.evaluate = (context) => {
    context.self.validators = isInRoute(context, [PPORoute]) ? [validation.required] : [];
    context.self.validators.push(validation.fileSize(10));
    context.self.validators.push(validation.fileTypes(fileTypes));
  };

  formModel.items.bestandenEnAfronden.items.genogram.evaluate = (context) => {
    context.self.validators = isInRoute(context, [PPORoute]) ? [validation.required] : [];
    context.self.validators.push(validation.fileSize(10));
    context.self.validators.push(validation.fileTypes(fileTypes));
  };

  formModel.items.bestandenEnAfronden.items.onderzoeksverslagen.evaluate = (context) => {
    context.self.validators = isInRoute(context, [PPORoute]) ? [validation.required] : [];
    context.self.validators.push(validation.fileSize(10));
    context.self.validators.push(validation.fileTypes(fileTypes));
  };

  formModel.items.bestandenEnAfronden.items.zorgtoewijzing.validators.push(validation.fileTypes(fileTypes));
  formModel.items.bestandenEnAfronden.items.overigeDocumenten.type.validators.push(validation.fileTypes(fileTypes));

  formModel.items.bestandenEnAfronden.items.zorgtoewijzing.validators.push(validation.fileSize(10));
  formModel.items.bestandenEnAfronden.items.overigeDocumenten.type.validators.push(validation.fileSize(10));

  formModel.items.gegevensClient.items.jeugdige.items.naamInstelling.evaluate = (context) => {
    context.self.isActief = verblijfplaatsInstelling(context, [GezinshuisPlaats, InstellingPlaats, AndersPlaats]);
  };

  formModel.items.gegevensClient.items.jeugdige.items.straat.evaluate = (context) => {
    context.self.isActief = verblijfplaatsInstelling(context, [GezinshuisPlaats, InstellingPlaats, AndersPlaats]);
  };
  formModel.items.gegevensClient.items.jeugdige.items.huisnummer.evaluate = (context) => {
    context.self.isActief = verblijfplaatsInstelling(context, [GezinshuisPlaats, InstellingPlaats, AndersPlaats]);
  };
  formModel.items.gegevensClient.items.jeugdige.items.toevoeging.evaluate = (context) => {
    context.self.isActief = verblijfplaatsInstelling(context, [GezinshuisPlaats, InstellingPlaats, AndersPlaats]);
  };
  formModel.items.gegevensClient.items.jeugdige.items.postcode.evaluate = (context) => {
    context.self.isActief = verblijfplaatsInstelling(context, [GezinshuisPlaats, InstellingPlaats, AndersPlaats]);
  };
  formModel.items.gegevensClient.items.jeugdige.items.plaats.evaluate = (context) => {
    context.self.isActief = verblijfplaatsInstelling(context, [GezinshuisPlaats, InstellingPlaats, AndersPlaats]);
  };

  const { clientVerblijf } = formModel.items.gegevensClient.items.jeugdige.items;
  const clientVerblijfDefaultOptions = clientVerblijf.opties;
  clientVerblijf.evaluate = (context) => {
    if (isInRoute(context, VIBRoute)) {
      context.self.opties = clientVerblijfDefaultOptions.filter((x) => x.label !== AndersPlaats);
      return;
    }
    context.self.opties = clientVerblijfDefaultOptions;
  };

  formModel.items.gegevensClient.items.jeugdige.items.clientVerblijfAnders.evaluate = (context) => {
    context.self.isActief = context.group.items.clientVerblijf.antwoord === AndersPlaats;
  };

  formModel.items.gegevensClient.items.biologischeOuder.items.pPOBeideOuders.evaluate = (context) => {
    context.self.isActief = !isInRoute(context, VIBRoute);
    context.self.validators = isInRoute(context, PPORoute)
      ? [validation.required]
      : [];
  };

  return {
    submitted: false,
    error: false,
    errorMessage: '',
    labels: {},
    formModel,
  };
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },
    setError(state, error, message) {
      state.error = error;
      state.errorMessage = message;
    },

    setSubmitted(state, submitted) {
      state.submitted = submitted;
    },
    clearQuestionsForClone(state) {
      const questions = Object.values(state.formModel.items.gegevensClient.items.jeugdige.items);
      questions.filter((q) => q.antwoord).forEach((q) => {
        q.antwoord = '';
      });
    },

  },
  actions: {

    submit({ commit, state }) {
      const form = state.formModel;

      modelParser.submit(form, (data) => {
        commit('incrementLoading', true, { root: true });

        axios.post('/api/AanmeldingOpvoedondersteuning', data)
          .then(() => {
            commit('setSubmitted', true);
            commit('decrementLoading', true, { root: true });
          })
          .catch(() => {
            commit('setError', true);
            commit('decrementLoading', true, { root: true });
          });
      });
    },
    startClone({ commit }) {
      commit('clearQuestionsForClone');
      commit('setSubmitted', false);
    },
  },
  getters: {
    isInRoute: (state) => (route) => {
      const context = { form: state.formModel };
      return isInRoute(context, route);
    },
    verblijfplaatsInstelling: (state) => (route) => {
      const context = { form: state.formModel };
      return verblijfplaatsInstelling(context, route);
    },
  },
};
