<template>
  <div id="app">
    <environment-sticker />
    <router-view />
  </div>
</template>

<script>
import EnvironmentSticker from '@/components/EnvironmentSticker';

export default {
  components: { EnvironmentSticker },
};

</script>

<style lang="scss">
  @import "assets/scss/vendor/vue-simple-spinner";
  @import "vue-toasted/dist/vue-toasted.min.css";
  @import "@fortawesome/fontawesome-svg-core/styles.css";

  @import "assets/scss/utils/helpers";
  @import "assets/scss/utils/layout";

  @import "assets/scss/atomic/atoms/root";
  @import "assets/scss/atomic/atoms/typography";
  @import "assets/scss/atomic/atoms/forms";
  @import "assets/scss/atomic/atoms/table";

  @import "assets/scss/atomic/molecules/badge";
  @import "assets/scss/atomic/molecules/button";
  @import "assets/scss/atomic/molecules/button-group";
  @import "assets/scss/atomic/molecules/card";
  @import "assets/scss/atomic/molecules/checklist-item";
  @import "assets/scss/atomic/molecules/file-input";
  @import "assets/scss/atomic/molecules/list-pagination";
  @import "assets/scss/atomic/molecules/list-search";
  @import "assets/scss/atomic/molecules/message";
  @import "assets/scss/atomic/molecules/switch";
  @import "assets/scss/atomic/molecules/task";
  @import "assets/scss/atomic/molecules/total";
  @import "assets/scss/atomic/molecules/tooltip";
  @import "assets/scss/atomic/molecules/uploaded-file";

  @import "assets/scss/atomic/organisms/page-title";
</style>
