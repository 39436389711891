import { formItemModel as FormItem, validation } from 'icatt-vue-forms';

import customValidation from '@/helpers/functions/validation.js';
import {
  Derde, Nee, Ja, Onbekend, jaNee, jaNeeOnbekend, BiologischeOuders, BestandRoute, NetwerkRoute, CrisisRoute, OvernameRoute, GezinshuisRoute,
} from '../constants';
import { geenSteun, isInRoute } from '../helpers.js';
import { optie } from '../../helpers';

const stapVereisten = new FormItem({
  items: {
    vorigeJeugdige: new FormItem({
    }),
    route: new FormItem({
      opties: [
        optie(BestandRoute),
        optie(CrisisRoute),
        optie(NetwerkRoute),
        optie(OvernameRoute),
        optie(GezinshuisRoute),
      ],
      validators: [validation.required],
    }),
    beslisboom: new FormItem({ // Beslisboom
      items: {
        beperkingVJeugdige: new FormItem({
          shouldClearForClone: true,
          opties: jaNeeOnbekend,
          validators: [validation.required],
        }),
        beperkingLJeugdige: new FormItem({
          shouldClearForClone: true,
          opties: jaNeeOnbekend,
          validators: [validation.required],
        }),
        beperkingBioOuders: new FormItem({
          evaluate(context) {
            const { beperkingVJeugdige, beperkingLJeugdige } = context.group.items;
            this.isActief = beperkingVJeugdige.antwoord === Nee && beperkingLJeugdige.antwoord === Nee;
          },
          opties: jaNeeOnbekend,
          validators: [validation.required],
        }),
        beperkingPleegouders: new FormItem({
          evaluate(context) {
            const { antwoord, isActief } = context.group.items.beperkingBioOuders;
            this.isActief = isActief && antwoord === Nee && isInRoute(context, [NetwerkRoute, OvernameRoute]);
          },
          opties: jaNeeOnbekend,
          validators: [validation.required],
        }),
        wieGezag: new FormItem({
          evaluate(context) {
            const matchingAnswers = [Ja, Onbekend];
            const {
              beperkingVJeugdige, beperkingLJeugdige, beperkingBioOuders, beperkingPleegouders,
            } = context.group.items;
            this.isActief = [beperkingVJeugdige,
              beperkingLJeugdige,
              beperkingBioOuders,
              beperkingPleegouders].some((x) => matchingAnswers.includes(x.antwoord));
          },
          opties: [
            optie(BiologischeOuders),
            optie('Gecertificeerde instelling'),
            optie(Derde),
          ],
          validators: [validation.required],
        }),
        steunPlaatsing: new FormItem({
          evaluate(context) {
            this.isActief = context.group.items.wieGezag.antwoord === Derde;
          },
          opties: jaNee,
          validators: [validation.required],
        }),
        boSteunPlaatsing: new FormItem({
          evaluate(context) {
            this.isActief = context.group.items.wieGezag.antwoord === BiologischeOuders;
          },
          opties: jaNee,
          validators: [validation.required],
        }),
        juridischeMaatregel: new FormItem({
          opties: jaNee,
          evaluate(context) {
            this.isActief = geenSteun(context);
          },
          validators: [validation.required],
        }),
      },
    }),
  },
});

export {
  stapVereisten,
};
