import axios from 'axios';

const getInitialState = () => ({
  loaded: false,
  error: false,
  data: {},
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => {
      console.log('reset profiel');
      Object.assign(state, getInitialState());
    },

    setError(state, error) {
      state.error = error;
    },

    setProfile(state, data) {
      state.data = data;
      state.loaded = true;
    },

  },

  actions: {

    loadProfile({
      state, rootState, commit, dispatch,
    }) {
      if (!state.loaded || state.error) {
        commit('incrementLoading', true, { root: true });
        commit('setError', false);

        axios.get('/api/profile')
          .then((response) => {
            commit('setProfile', response.data);
          })
          .catch(() => {
            commit('setError', true);
          })
          .finally(() => commit('decrementLoading', true, { root: true }));
      }
    },
  },
  getters: {},
};
