import Vue from 'vue';

export default Vue.filter('euroFormat', (value) => {
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  return formatter.format(value);
});
