const mergeLabels = (labels, formModel) => {
  Object.keys(labels).forEach((key) => {
    let element = formModel;
    const labelParts = key.split('.').slice(1);

    labelParts.forEach((path) => {
      if (Array.isArray(element.items)) {
        element = element.type || element.items[0];
      }
      if (element.items && element.items[path]) {
        element = element.items[path];
      } else {
        const error = { message: 'no element found', key, element };
        throw error;
      }
    });

    const label = labels[key];
    if (label) {
      element.label = label;
    }
  });
};

const doForItems = (items, func) => {
  if (!items || !func) {
    return;
  }

  if (Array.isArray(items)) {
    items.forEach(func);
  } else if (typeof items === 'object') {
    Object.values(items).forEach(func);
  }
};

const doRecursive = (formItem, func) => {
  if (!formItem || !func) {
    return;
  }

  func(formItem);

  doForItems(formItem.items, (i) => doRecursive(i, func));
};

const optie = (tekst) => ({ label: tekst, value: tekst });

export {
  mergeLabels,
  doForItems,
  doRecursive,
  optie,
};
