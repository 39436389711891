import Vue from 'vue';

export default Vue.filter('decimalFormat', (value) => {
  try {
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'decimal',
      useGrouping: true,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  } catch {
    return value;
  }
});
