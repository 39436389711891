import { optie } from '../helpers';

const PPORoute = 'PPO';
const VIBRoute = 'VIB';
const AdviesRoute = 'Advies';
const Ja = 'Ja';
const Nee = 'Nee';
const Onbekend = 'Onbekend';

const Derde = 'Derde';

const VIBDoelgroepPleegouderEnPleegkind = 'Pleegouder en pleegkind';
const VIBDoelgroepOuderEnKind = 'Ouder en kind';

const OptieAnders = 'Anders';

const GezinshuisPlaats = 'Gezinshuis';
const InstellingPlaats = 'Instelling';
const PleeggezinPlaats = 'Pleeggezin';

const AndersPlaats = OptieAnders;

const jaNee = [
  optie(Ja),
  optie(Nee),
];

const jaNeeOnbekend = [
  ...jaNee,
  optie(Onbekend),
];

export {
  Ja,
  Nee,
  Onbekend,
  Derde,
  jaNee,
  jaNeeOnbekend,
  PPORoute,
  VIBRoute,
  AdviesRoute,
  VIBDoelgroepPleegouderEnPleegkind,
  VIBDoelgroepOuderEnKind,
  OptieAnders,
  GezinshuisPlaats,
  InstellingPlaats,
  AndersPlaats,
  PleeggezinPlaats,
};
