import { formItemModel as FormItem, validation } from 'icatt-vue-forms';

import customValidation from '@/helpers/functions/validation.js';
import {
  jaNee, jaNeeOnbekend, woontElders, ouderIsDakloos,
} from '../constants';
import {
  getOpvoederText, getOpvoedersText,
  isTweedeOuderBekend, gegevensOudersAreRequired,
} from '../helpers';
import { optie } from '../../helpers';

const PhoneNumbers = () => new FormItem({
  items: [new FormItem({
    validators: [validation.required, validation.phone_nl],
  })],
  add(items) {
    items.push(new FormItem({
      validators: [validation.phone_nl],
    }));
  },
});

const VolledigeNaam = (isRequiredFunction) => new FormItem({
  items: {
    voornamen: new FormItem({
      evaluate(context) {
        if (!isRequiredFunction || isRequiredFunction(context)) {
          this.validators = [validation.required];
        } else {
          this.validators = [];
        }
      },
    }),
    tussenvoegsel: new FormItem(),
    achternaam: new FormItem({
      evaluate(context) {
        if (!isRequiredFunction || isRequiredFunction(context)) {
          this.validators = [validation.required];
        } else {
          this.validators = [];
        }
      },
    }),
  },
});

const Adres = (isRequiredFunction, isActiefFunction) => new FormItem({
  evaluate(context) {
    if (isActiefFunction) {
      this.isActief = isActiefFunction(context);
    }
  },
  items: {
    straat: new FormItem({
      evaluate(context) {
        if (!isRequiredFunction || isRequiredFunction(context)) {
          this.validators = [validation.required];
        } else {
          this.validators = [];
        }
      },
    }),
    huisnummer: new FormItem({
      validators: [validation.required, validation.number],
      evaluate(context) {
        if (!isRequiredFunction || isRequiredFunction(context)) {
          this.validators = [validation.required, validation.number, validation.maxLength(6)];
        } else {
          this.validators = [validation.number, validation.maxLength(6)];
        }
      },
    }),
    toevoeging: new FormItem({
      validators: [validation.maxLength(6)],
    }),
    postcode: new FormItem({
      validators: [validation.required, validation.postcode],
      evaluate(context) {
        if (!isRequiredFunction || isRequiredFunction(context)) {
          this.validators = [validation.required, validation.postcode];
        } else {
          this.validators = [validation.postcode];
        }
      },
    }),
    plaats: new FormItem({
      evaluate(context) {
        if (!isRequiredFunction || isRequiredFunction(context)) {
          this.validators = [validation.required];
        } else {
          this.validators = [];
        }
      },
    }),
  },
});

const KindItem = () => new FormItem({
  items: {
    volledigeNaam: new VolledigeNaam(() => false),
    geboortedatum: new FormItem({
      validators: [validation.datum, customValidation.dateInThePast],
    }),
    geslacht: new FormItem({
      opties: [
        { label: 'Jongen', value: 'Jongen' },
        { label: 'Meisje', value: 'Meisje' },
      ],
    }),
    kindIsEen: new FormItem({
      evaluate(context) {
        const opvoeder = getOpvoederText(context);
        const opvoeders = getOpvoedersText(context);

        this.opties = [
          { label: `Een biologisch kind van beide ${opvoeders} `, value: `Een biologisch kind van beide ${opvoeders} ` },
          { label: `Een biologisch kind van ${opvoeder} 1 `, value: `Een biologisch kind van ${opvoeder} 1 ` },
          { label: `Een biologisch kind van ${opvoeder} 2 `, value: `Een biologisch kind van ${opvoeder} 2 ` },
          { label: 'Een adoptiekind ', value: 'Een adoptiekind ' },
          { label: 'Een pleegkind', value: 'Een pleegkind' },
        ];
      },
      opties: [],
    }),
  },
});

const OpvoederItem = (isTweede) => new FormItem({
  evaluate(context) {
    const { antwoord } = context.form.items.overigeInformatie.items.blokPleeggezin.items.isErEenTweedeOpvoeder;
    this.isActief = !isTweede || antwoord.startsWith('Ja');
  },
  items: {
    volledigeNaam: new VolledigeNaam(),
    geboortedatum: new FormItem({
      validators: [validation.required, validation.datum, customValidation.dateInThePast],
    }),
    adres: new Adres(null, (context) => {
      const { antwoord } = context.form.items.overigeInformatie.items.blokPleeggezin.items.isErEenTweedeOpvoeder;
      return !isTweede || antwoord.endsWith('elders');
    }),
    telefoonnummers: new PhoneNumbers(),
    emailadres: new FormItem({
      validators: [validation.email],
    }),
  },
});

const OuderItem = (isTweede) => new FormItem({
  evaluate(context) {
    const { antwoord } = isTweedeOuderBekend(context);
    this.isActief = !isTweede || antwoord.startsWith('Ja');
  },
  items: { // GegevensOuder
    volledigeNaam: new VolledigeNaam(gegevensOudersAreRequired),
    geboortedatum: new FormItem({
      evaluate(context) {
        if (gegevensOudersAreRequired(context)) {
          this.validators = [validation.required, validation.datum, customValidation.dateInThePast];
        } else {
          this.validators = [validation.datum, customValidation.dateInThePast];
        }
      },
    }),
    hetFeitelijkVerblijfsadresOuder: new FormItem({
      opties: [
        optie(ouderIsDakloos),
      ],
      evaluate(context) {
        const { antwoord } = context.group.items.jeugdigeContactMetOuder;
        this.isActief = !isTweede || isTweedeOuderBekend(context).antwoord === woontElders;
      },
    }),

    adres: new Adres((gegevensOudersAreRequired), (context) => {
      const isdakloos = context.parent.items.hetFeitelijkVerblijfsadresOuder.antwoord;

      return (!isTweede || isTweedeOuderBekend(context).antwoord === woontElders) && !(isdakloos.length === 1 && isdakloos[0] === ouderIsDakloos);
    }),
    jeugdigeContactMetOuder: new FormItem({
      opties: jaNee,
    }),
    emailadres: new FormItem({
      validators: [validation.email],
    }),

    jeugdigeContactMetOuderToelichting: new FormItem({
      evaluate(context) {
        const { antwoord } = context.group.items.jeugdigeContactMetOuder;
        this.isActief = antwoord === 'Ja';
      },
    }),
    bijkomendeProblematiekOuder: new FormItem({
      opties: jaNeeOnbekend,
    }),
    bijkomendeProblematiekOuderToelichting: new FormItem({
      evaluate(context) {
        const { antwoord } = context.group.items.bijkomendeProblematiekOuder;
        this.isActief = antwoord === 'Ja';
      },
    }),
  },
});

export {
  PhoneNumbers,
  VolledigeNaam,
  Adres,
  KindItem,
  OpvoederItem,
  OuderItem,
};
