import { formItemModel as FormItem, validation } from 'icatt-vue-forms';

import customValidation from '@/helpers/functions/validation.js';
import {
  Ja, Nee, jaNee, jaNeeOnbekend, jeugdigeWoontInNetwerk, NetwerkRoute, BestandRoute,
  CrisisRoute, OvernameRoute, GezinshuisRoute,
} from '../constants';
import {
  isInRoute, prefill, getOpvoederText, shouldStop,
} from '../helpers.js';
import { OpvoederItem, KindItem, AndereOrgBetrokkenItem } from '../blokken/blokken';

const stapOverigeGegevens = new FormItem({
  evaluate(context) {
    this.isActief = !shouldStop(context);
  },
  items: { // OverigeInformatie
    contactOudersNetwerkPleeggezin: new FormItem({
      evaluate(context) {
        this.isActief = isInRoute(context, NetwerkRoute);
      },
    }),
    blokPleeggezin: new FormItem({
      items: {
        opvoeder1: new OpvoederItem(),
        iban: new FormItem({
          validators: [validation.required, validation.ibanNl],
        }),
        tenNameVan: new FormItem({
          validators: [validation.required],
        }),
        isErEenTweedeOpvoeder: new FormItem({
          validators: [validation.required],
          evaluate(context) {
            const opvoederText = getOpvoederText(context);
            this.opties = [
              { value: `Ja, deze ${opvoederText} woont op het adres van ${opvoederText} 1`, label: `Ja, deze ${opvoederText} woont op het adres van ${opvoederText} 1` },
              { value: `Ja, deze ${opvoederText} woont elders`, label: `Ja, deze ${opvoederText} woont elders` },
              { value: Nee, label: Nee },
            ];
          },
          opties: [],
        }),
        opvoeder2: new OpvoederItem(true),
        zijnErKinderen: new FormItem({
          opties: jaNee,
        }),
        kinderen: new FormItem({
          evaluate(context) {
            const { antwoord } = context.form.items.overigeInformatie.items.blokPleeggezin.items.zijnErKinderen;
            this.isActief = antwoord === Ja;
          },
          items: [new KindItem()],
          add(items, context) {
            const kind = new KindItem();
            // evaluate moet aangeroepen worden omdat,
            // de labels van de opties dynamisch zijn en nu opnieuw bepaald moeten worden
            // dit wordt in de evaluate geregeld,zodat het ook aangepast wordt als de route wijzigt
            kind.items.kindIsEen.evaluate(context);
            items.push(kind);
          },
        }),
      },
      evaluate(context) {
        this.isActief = isInRoute(context, [NetwerkRoute, OvernameRoute]) || (isInRoute(context, [CrisisRoute]) && context.form.items.algemeneGegevens.items.gegevensJeugdige.items.jeugdigeWoontCrisis.antwoord === jeugdigeWoontInNetwerk);
      },
    }),
    blokBeperkingOpvoeders: new FormItem({
      items: {
        beperkingOpvoeders: new FormItem({
          evaluate(context) {
            prefill(this, context.form.items.vereisten.items.beslisboom.items.beperkingPleegouders);
          },
          opties: jaNeeOnbekend,
          validators: [validation.required],
        }),
        beperkingOpvoedersToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.beperkingOpvoeders;
            this.isActief = antwoord === Ja;
          },
          validators: [validation.required],
        }),
      },
      evaluate(context) {
        this.isActief = isInRoute(context, [NetwerkRoute, OvernameRoute]) || (isInRoute(context, [CrisisRoute]) && context.form.items.algemeneGegevens.items.gegevensJeugdige.items.jeugdigeWoontCrisis.antwoord === jeugdigeWoontInNetwerk);
      },
    }),
    blokVeiligheidscheck: new FormItem({
      items: {
        maatregel: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        maatregelToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.maatregel;
            this.isActief = antwoord === Ja;
          },
          validators: [validation.required],
        }),
        verslaving: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        verslavingToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.verslaving;
            this.isActief = antwoord === Ja;
          },
          validators: [validation.required],
        }),
        psychiatrisch: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        psychiatrischToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.psychiatrisch;
            this.isActief = antwoord === Ja;
          },
          validators: [validation.required],
        }),
        strafblad: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        strafbladToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.strafblad;
            this.isActief = antwoord === Ja;
          },
          validators: [validation.required],
        }),
        schuldhulp: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        schuldhulpToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.schuldhulp;
            this.isActief = antwoord === Ja;
          },
          validators: [validation.required],
        }),
        huishouden: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        huishoudenToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.huishouden;
            this.isActief = antwoord === Ja;
          },
          validators: [validation.required],
        }),
        gebeurtenis: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        gebeurtenisToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.gebeurtenis;
            this.isActief = antwoord === Ja;
          },
          validators: [validation.required],
        }),
        andereOrg: new FormItem({
          opties: jaNee,
          evaluate(context) {
            this.isActief = isInRoute(context, [NetwerkRoute]);
          },
        }),
        andereOrgToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.andereOrg;
            this.isActief = antwoord === 'Ja' && isInRoute(context, [NetwerkRoute]);
          },
        }),

        overigeZaken: new FormItem(),
      },
      evaluate(context) {
        this.isActief = isInRoute(context, [NetwerkRoute]) || (isInRoute(context, [CrisisRoute]) && context.form.items.algemeneGegevens.items.gegevensJeugdige.items.jeugdigeWoontCrisis.antwoord === jeugdigeWoontInNetwerk);
      },
    }),
    overnameVragen: new FormItem({
      evaluate(context) {
        this.isActief = isInRoute(context, OvernameRoute);
      },
      items: {
        isErEenGeldigeBepaling: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        bepalingVragen: new FormItem({
          evaluate(context) {
            const { antwoord } = context.form.items.overigeInformatie.items.overnameVragen.items.isErEenGeldigeBepaling;
            this.isActief = antwoord === Ja;
          },
          items: {
            bepalingGeldigVanaf: new FormItem({
              validators: [validation.required, validation.datum],
            }),
            bepalingGeldigTot: new FormItem({
              validators: [validation.required, validation.datum, customValidation.dateInTheFuture],
            }),
            jeugdzorgregio: new FormItem({
              validators: [validation.required],
            }),
            betaaldeGemeente: new FormItem({
              validators: [validation.required],
            }),
          },
        }),
        redenOvername: new FormItem({
          validators: [validation.required],
        }),
        isGezinGescreend: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        isGezingGescreendVragen: new FormItem({
          evaluate(context) {
            const { antwoord } = context.form.items.overigeInformatie.items.overnameVragen.items.isGezinGescreend;
            this.isActief = antwoord === Ja;
          },
          items: {
            gezinGescreendDoorWie: new FormItem({
              validators: [validation.required],
            }),
            gezinGescreendDatum: new FormItem({
              validators: [validation.required, validation.datum, customValidation.dateInThePast],
            }),
          },
        }),
        gemeldVerwijsindex: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        incidentenVoorgedaan: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        meerderePleegkinderenBegeleidDoorInstantie: new FormItem(),
        aandachtspuntenBegeleiding: new FormItem(),
      },
    }),
    crisisVragen: new FormItem({
      evaluate(context) {
        this.isActief = isInRoute(context, CrisisRoute);
      },
      items: {
        crisisaanmeldingAanleiding: new FormItem(),
        crisisaanmeldingDoel: new FormItem(),
        prognoseVerblijfsduurEnPerspectief: new FormItem(),
        ontwikkelingEnGedragJeugdige: new FormItem(),
        afsprakenBezoekregeling: new FormItem(),
        vermeldInVerwijsindex: new FormItem(),
      },
    }),
    bestandEnGezinshuisOverig: new FormItem({
      evaluate(context) {
        this.isActief = isInRoute(context, [BestandRoute, GezinshuisRoute]);
      },
      items: {
        andereOrg: new FormItem({
          opties: jaNee,
          evaluate(context) {
            this.isActief = isInRoute(context, [BestandRoute, GezinshuisRoute]);
          },
        }),
        andereOrgToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.andereOrg;
            this.isActief = antwoord === 'Ja' && isInRoute(context, [BestandRoute, GezinshuisRoute]);
          },
        }),

      },
    }),

    opmerkingen: new FormItem(),
  },
});

export { stapOverigeGegevens };
