import axios from 'axios';

const getInitialState = () => ({
  pleegKinderenOpties: [],
  kostensoorten: [],
  submitted: false,
  errors: {
    PostDeclaratieError: false,

    DeclarantLoadingError: false,

    kostensoortenLoadingError: false,

  },
  declarant: null,
  form: {},

});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },
    setSubmitted(state, submitted) {
      state.submitted = submitted;
    },

    setPostDeclaratieError(state, error) {
      state.errors.postDeclaratieError = error;
    },

    setDeclarantLoadingError(state, error) {
      state.errors.declarantLoadingError = error;
    },

    setKostensoortenLoadingError(state, error) {
      state.errors.kostensoortenLoadingError = error;
    },

    setPleegKinderenOpties(state, options) {
      state.pleegKinderenOpties = options;
    },

    setKostensoorten(state, options) {
      state.kostensoorten = options;
    },

    setDeclarant(state, data) {
      state.declarant = data;
    },
  },
  actions: {

    async getKostensoorten({
      state, rootState, commit, dispatch,
    }) {
      try {
        commit('incrementLoading', true, { root: true });
        commit('setKostensoortenLoadingError', false);
        commit('setSubmitted', false);
        const response = await axios.get('/api/Kostensoorten');
        commit('setKostensoorten', response.data);
      } catch (error) {
        commit('setKostensoortenLoadingError', true);
      } finally {
        commit('decrementLoading', true, { root: true });
      }
    },

    async setDeclarant({
      state, rootState, commit, dispatch,
    }, id) {
      try {
        commit('incrementLoading', true, { root: true });
        commit('setDeclarantLoadingError', false);
        commit('setSubmitted', false);
        const response = await axios.get(`/api/declaranten/${id}`);
        commit('setDeclarant', response.data);
      } catch (error) {
        commit('setDeclarantLoadingError', true);
      } finally {
        commit('decrementLoading', true, { root: true });
      }
    },

    postDeclaraties({
      state, rootState, commit, dispatch,
    }, formModel) {
      commit('setPostDeclaratieError', false);
      commit('incrementLoading', true, { root: true });

      axios.post('/api/Declaraties', formModel)
        .then((response) => {
          commit('decrementLoading', true, { root: true });
          commit('setSubmitted', true);
        })
        .catch((error) => {
          commit('decrementLoading', true, { root: true });
          commit('setPostDeclaratieError', true);
        });
    },

    reset({ commit }) {
      commit('reset');
    },

  },
  getters: {},
};
