import {
  VIBDoelgroepPleegouderEnPleegkind, Nee,
} from './constants';

const isInRoute = (context, route) => {
  if (Array.isArray(route)) return route.some((x) => isInRoute(context, x));
  const currentRoute = context.form.items.vereisten.items.soortAanvraag.antwoord;
  if (currentRoute) return currentRoute.toLowerCase() === route.toLowerCase();
  return false;
};

const verblijfplaatsInstelling = (context, plaats) => {
  if (Array.isArray(plaats)) return plaats.some((x) => verblijfplaatsInstelling(context, x));
  const currentplaats = context.group.items.clientVerblijf.antwoord;
  if (currentplaats) return currentplaats.toLowerCase() === plaats.toLowerCase();
  return false;
};
const vIBDoelgroep = (context) => context.form.items.vereisten.items.vIBAanvraag.items.wieVIB.antwoord;

const isRequiredPleegouderEnPleegkind = (context) => vIBDoelgroep(context) === VIBDoelgroepPleegouderEnPleegkind;

const isBiologischeOuder2ZelfdeAdres = (context) => context.form.items.gegevensClient.items.biologischeOuder.items.gegevensOuder2.items.zelfdeAdres.antwoord === Nee;

export {
  isInRoute, vIBDoelgroep, verblijfplaatsInstelling, isRequiredPleegouderEnPleegkind, isBiologischeOuder2ZelfdeAdres,
};
