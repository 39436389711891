import { formItemModel as FormItem, validation } from 'icatt-vue-forms';

import customValidation from '@/helpers/functions/validation.js';
import { answerToDate } from '@/forms/helpers';
import { differenceInCalendarDays, isValid as isValidDate } from 'date-fns';
import {
  jongenMeisje, Ja, Nee, jaNee, jeugdigeWoontBijOuders, jeugdigeWoontInNetwerk, NetwerkRoute, CrisisRoute, OvernameRoute, BestandRoute, inEenInstelling, anders, bijAndereOpvoeders, GezinshuisRoute, Voltijd, Deeltijd, VoltijdDeeltijd,
} from '../constants';
import { isInRoute, shouldStop } from '../helpers';
import { PhoneNumbers, VolledigeNaam } from '../blokken/blokken';
import { optie } from '../../helpers';

const jeugdigeWoontNietBijOuders = (context) => {
  const { jeugdigeWoontBestand, jeugdigeWoontCrisis } = context.form.items.algemeneGegevens.items.gegevensJeugdige.items;

  return [jeugdigeWoontBestand, jeugdigeWoontCrisis].some((q) => q.isActief && [bijAndereOpvoeders, inEenInstelling, jeugdigeWoontInNetwerk, anders].includes(q.antwoord));
};

const stapAlgemeneGegevens = new FormItem({
  evaluate(context) {
    this.isActief = !shouldStop(context);
  },
  items: { // AlgemeneGegevens
    gegevensMelder: new FormItem({
      items: { // GegevensMelder
        volledigeNaam: new VolledigeNaam(),
        organisatie: new FormItem({
          validators: [validation.required],
        }),
        organisatieIsGecertificeerd: new FormItem({
          opties: jaNee,
          validators: [validation.required],
          evaluate(context) {
            this.isActief = context.group.items.bentUVerwijzer.antwoord === Ja;
          },
        }),
        telefoonnummers: new PhoneNumbers(),
        emailadres: new FormItem({
          validators: [validation.required, validation.email],
        }),
        bentUVerwijzer: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
      },
    }),
    gegevensVerwijzer: new FormItem({
      evaluate(context) {
        const { antwoord } = context.form.items.algemeneGegevens.items.gegevensMelder.items.bentUVerwijzer;
        this.isActief = antwoord === Nee;
      },
      items: {
        volledigeNaam: new VolledigeNaam(),
        organisatie: new FormItem({
          validators: [validation.required],
        }),
        organisatieIsGecertificeerd: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        telefoonnummers: new PhoneNumbers(),
        emailadres: new FormItem({
          validators: [validation.required, validation.email],
        }),
      },
    }),
    gegevensJeugdige: new FormItem({
      shouldClearForClone: true,
      items: { // GegevensJeugdige
        volledigeNaam: new VolledigeNaam(),
        verwachteGeboortedatum: new FormItem({
          validators: [validation.required, validation.datum],
        }),
        geboortePlaats: new FormItem(),
        geslacht: new FormItem({
          opties: jongenMeisje,
        }),
        heeftBSN: new FormItem({
          opties: jaNee,
          validators: [validation.required],
        }),
        bSN: new FormItem({
          validators: [validation.required, validation.bsn],
          evaluate(context) {
            this.isActief = context.form.items.algemeneGegevens.items.gegevensJeugdige.items.heeftBSN.antwoord === Ja;
          },
        }),
        waaromGeenBSN: new FormItem({
          validators: [validation.required],
          evaluate(context) {
            this.isActief = context.form.items.algemeneGegevens.items.gegevensJeugdige.items.heeftBSN.antwoord === Nee;
          },
        }),
        jeugdigeWoontBestand: new FormItem({
          opties: [
            optie(jeugdigeWoontBijOuders),
            optie(inEenInstelling),
            optie(bijAndereOpvoeders),
            optie(anders),
          ],
          validators: [validation.required],
          evaluate(context) {
            this.isActief = isInRoute(context, [BestandRoute, GezinshuisRoute]);
          },
        }),
        jeugdigeWoontCrisis: new FormItem({
          opties: [
            optie(jeugdigeWoontBijOuders),
            optie(inEenInstelling),
            optie(jeugdigeWoontInNetwerk),
            optie(anders),
          ],
          validators: [validation.required],
          evaluate(context) {
            this.isActief = isInRoute(context, [CrisisRoute]);
          },
        }),
        waarWoontJeugdigeToelichting: new FormItem({
          evaluate(context) {
            this.isActief = jeugdigeWoontNietBijOuders(context);
          },
          validators: [validation.required],
        }),

        redenJeugdigeWoontInEenInstelling: new FormItem({
          opties: [],
          validators: [validation.required],
          evaluate(context) {
            const { jeugdigeWoontBestand } = context.form.items.algemeneGegevens.items.gegevensJeugdige.items;
            this.isActief = jeugdigeWoontBestand.isActief && jeugdigeWoontBestand.antwoord === inEenInstelling;
            this.opties = [
              optie('Crisis'),
              optie('Onderzoek/behandeling'),
              optie('Overbrugging'),
              optie('Wonen'),
            ];
          },
        }),
        jeugdigeWoontContactpersoon: new FormItem({
          evaluate(context) {
            this.isActief = jeugdigeWoontNietBijOuders(context) && isInRoute(context, [BestandRoute, GezinshuisRoute]);
          },
          validators: [validation.required],
        }),
        jeugdigeWoontContactgegevens: new FormItem({
          evaluate(context) {
            this.isActief = jeugdigeWoontNietBijOuders(context) && isInRoute(context, [BestandRoute, GezinshuisRoute]);
          },
          validators: [validation.required],
        }),
      },

    }),
    vragenBestand: new FormItem({
      items: { // VragenBestand
        startDatum: new FormItem({
        }),
        redenVormZorg: new FormItem({
          validators: [validation.required],
        }),
        welkeGezinsvorm: new FormItem({
          opties: [
            ...VoltijdDeeltijd,
            optie('Gezinshuis'),
          ],
          validators: [validation.required],
        }),
        welkeGezinsvormToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.welkeGezinsvorm;
            this.isActief = antwoord === Deeltijd;
          },
          validators: [validation.required],
        }),
        welkeEisenGezinsvorm: new FormItem(),
      },
      evaluate(context) {
        this.isActief = isInRoute(context, [BestandRoute, GezinshuisRoute]);
      },
    }),

    vragenNetwerk: new FormItem({
      items: { // VragenNetwerk
        welkeVormZorg: new FormItem({
          opties: VoltijdDeeltijd,
          validators: [validation.required],
          evaluate(context) {
            this.isActief = isInRoute(context, NetwerkRoute);
          },
        }),
        welkeVormZorgToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.welkeVormZorg;
            this.isActief = antwoord === Deeltijd && isInRoute(context, NetwerkRoute);
          },
          validators: [validation.required],
        }),
        relatieJeugdigePleeggezin: new FormItem({
          validators: [validation.required],
          evaluate(context) {
            this.isActief = isInRoute(context, NetwerkRoute);
          },
        }),
        jeugdigeWoontNetwerk: new FormItem({
          opties: jaNee,
          validators: [validation.required],
          evaluate(context) {
            this.isActief = isInRoute(context, NetwerkRoute);
          },
        }),
        netwerkWoontSinds: new FormItem({
          validators: [validation.required, validation.datum, customValidation.dateInThePast],
          evaluate(context) {
            const { antwoord } = context.group.items.jeugdigeWoontNetwerk;
            this.isActief = antwoord === Ja && isInRoute(context, NetwerkRoute);
          },
        }),
        redenAanmelding: new FormItem({
          validators: [validation.required],
          evaluate(context) {
            const { antwoord, validationMessages } = context.group.items.netwerkWoontSinds;

            const antwoordDate = answerToDate(antwoord);
            const today = new Date();

            this.isActief = !validationMessages.length && isValidDate(antwoordDate) && differenceInCalendarDays(today, antwoordDate) > 365 && isInRoute(context, NetwerkRoute);
          },
        }),
        startDatum: new FormItem({
          validators: [validation.required],
          evaluate(context) {
            const { antwoord } = context.group.items.jeugdigeWoontNetwerk;
            this.isActief = antwoord === Nee && isInRoute(context, NetwerkRoute);
          },
        }),
      },
      evaluate(context) {
        this.isActief = isInRoute(context, NetwerkRoute);
      },
    }),
    vragenOvername: new FormItem({
      items: { // VragenOvername
        naamPleegzorgwerker: new FormItem({
          validators: [validation.required],
        }),
        organisatie: new FormItem({
          validators: [validation.required],
        }),
        telefoonnummers: new PhoneNumbers(),
        emailadres: new FormItem({
          validators: [validation.required, validation.email],
        }),
        welkeVormZorg: new FormItem({
          opties: VoltijdDeeltijd,
          validators: [validation.required],
        }),
        welkeVormZorgToelichting: new FormItem({
          evaluate(context) {
            const { antwoord } = context.group.items.welkeVormZorg;
            this.isActief = antwoord === Deeltijd && isInRoute(context, OvernameRoute);
          },
          validators: [validation.required],
        }),
        startDatum: new FormItem({
          validators: [validation.datum, customValidation.dateInThePast],
        }),
      },
      evaluate(context) {
        this.isActief = isInRoute(context, OvernameRoute);
      },
    }),

  },
});

export {
  stapAlgemeneGegevens,
};
