import axios from 'axios';

const getInitialState = () => ({
  bankmutaties: {
    mutaties: [],
    cursor: null,
    allLoaded: false,
    loading: true,
    error: false,
  },
  banken: {
    banken: [],
    loading: true,
    error: false,
  },
  selectedBankId: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => {
      Object.assign(state, getInitialState());
    },

    setError(state, error) {
      state.bankmutaties.error = error;
    },

    setErrorBanken(state, error) {
      state.banken.error = error;
    },

    setErrorBankgegevensZichtbaar(state, error) {
      state.bankgegevensZichtbaar.error = error;
    },

    setBankgegevens(state, data) {
      state.bankmutaties.mutaties = [
        ...state.bankmutaties.mutaties,
        ...data.mutaties,
      ];
      state.bankmutaties.cursor = data.cursor;
      state.bankmutaties.allLoaded = data.cursor == null;
    },

    setBanken(state, data) {
      state.banken.banken = data;
    },

    setLoading(state, data) {
      state.bankmutaties.loading = data;
    },

    setLoadingBanken(state, data) {
      state.banken.loading = data;
    },

    setSelectedBank(state, data) {
      state.selectedBankId = data;
    },

    clearMutaties(state) {
      state.bankmutaties.mutaties = [];
      state.bankmutaties.cursor = null;
      state.bankmutaties.allLoaded = false;
      state.bankmutaties.loading = true;
      state.bankmutaties.error = false;
    },
  },

  actions: {
    loadBankgegevens({
      state, rootState, commit, dispatch,
    }) {
      if (state.selectedBankId === null) {
        commit('clearMutaties', false);
        commit('setLoading', false);
        return;
      }

      if (!state.loaded || state.error) {
        commit('setLoading', true);
        commit('setError', false);

        const cursorQuery = (state.bankmutaties.cursor === null) ? '' : `&cursor=${state.bankmutaties.cursor}`;
        const query = `?rekening=${state.selectedBankId}${cursorQuery}`;

        return new Promise((resolve, reject) => {
          axios
            .get(`/api/bankmutaties${query}`)
            .then((response) => {
              commit('setBankgegevens', response.data);
              resolve(response);
            })
            .catch((error) => {
              commit('setError', true);
              reject(error);
            })
            .finally(() => commit('setLoading', false));
        });
      }
    },
    loadBanken({
      state, rootState, commit, dispatch,
    }, userId) {
      if (!state.loaded || state.error) {
        commit('setLoadingBanken', true);
        commit('setErrorBanken', false);

        const routeParam = userId
          ? `/${userId}`
          : '';

        axios
          .get(`/api/banken${routeParam}`)
          .then((response) => {
            commit('setBanken', response.data);
          })
          .catch(() => {
            commit('setErrorBanken', true);
          })
          .finally(() => commit('setLoadingBanken', false));
      }
    },

    reset({ commit }) {
      commit('reset');
    },

    bankSelected({ state, commit, dispatch }, bankId) {
      commit('setSelectedBank', bankId);
      commit('clearMutaties');
      dispatch('loadBankgegevens');
    },
  },
  getters: {},
};
