import axios from 'axios';

const getInitialState = () => ({
  error: false,
  errorMessage: '',
  days: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },
    setDays(state, days) {
      state.days = days;
    },

  },

  actions: {

    getDays({ commit }) {
      commit('incrementLoading', true, { root: true });
      axios.get('/api/Export')
        .then((response) => {
          commit('decrementLoading', true, { root: true });
          commit('setDays', response.data);
        })
        .catch((error) => {
          console.log('export', error);
          commit('decrementLoading', true, { root: true });
          commit('setError', true, error.response.data);
        });
    },
  },
  getters: {},
};
