import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import accountStoreModule from './stores/account';
import vermogensbeheerChecklistModule from './stores/vermogensbeheer/checklist';
import declaratieStoreModule from './stores/declaratie';
import declarerenStoreModule from './stores/declareren';
import profielStoreModule from './stores/profiel';
import bevestigEmailStoreModule from './stores/bevestigEmail';
import pleegkinderenStoreModule from './stores/pleegkinderen';
import wachtwoordVergetenStoreModule from './stores/wachtwoordVergeten';
import resetWachtwoordStoreModule from './stores/resetWachtwoord';
import aanmeldenJeugdige from './stores/aanmeldenJeugdige';
import opvoedondersteuning from './stores/opvoedondersteuning';
import exportModule from './stores/finallExport';
import bankgegevensModule from './stores/bankgegevens';
import notificaties from './stores/notificaties';

// Add a response interceptor
axios.interceptors.response.use((response) =>
  // Do something with response data
  response,
(error) => {
  // Do something with response error
  if (error.response.status === 404) {
    window.location.href = '/404.html';
  }
  return Promise.reject(error);
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: 0,
    isSidebarExpanded: document.body.clientWidth > 992,
    isNotificatiesVisible: false,
  },
  modules: {
    account: accountStoreModule,
    declaratie: declaratieStoreModule,
    declareren: declarerenStoreModule,
    profiel: profielStoreModule,
    bevestigEmail: bevestigEmailStoreModule,
    pleegkinderen: pleegkinderenStoreModule,
    wachtwoordVergeten: wachtwoordVergetenStoreModule,
    resetWachtwoord: resetWachtwoordStoreModule,
    aanmeldenJeugdige,
    finallExport: exportModule,
    vermogensbeheerChecklist: vermogensbeheerChecklistModule,
    bankgegevens: bankgegevensModule,
    opvoedondersteuning,
    notificaties,
  },
  mutations: {
    decrementLoading(state) {
      state.loading--;
    },
    incrementLoading(state) {
      state.loading++;
    },
    toggleSidebar(state, expanded) {
      expanded = expanded == undefined ? !state.isSidebarExpanded : expanded;
      state.isSidebarExpanded = expanded;
    },
    toggleNotificaties(state) {
      state.isNotificatiesVisible = !state.isNotificatiesVisible;
    },
  },
  actions: {
    resetAll(context) {
      console.log('reset all stores');
      context.commit('account/reset');
      context.commit('declaratie/reset');
      context.commit('declareren/reset');
      context.commit('profiel/reset');
      context.commit('bevestigEmail/reset');
      context.commit('pleegkinderen/reset');
      context.commit('wachtwoordVergeten/reset');
      context.commit('resetWachtwoord/reset');
      context.commit('bankgegevens/reset');
      context.commit('notificaties/reset');
    },
  },
});
