import { answerToDate } from '@/forms/helpers';
import { isAfter, isBefore, isValid as isValidDate } from 'date-fns';

const validation = {

  dateInThePast(value) {
    if (!value) return null;

    const d = answerToDate(value);
    const now = new Date();

    if (!isValidDate(d) || !isBefore(d, now)) {
      return 'Vul een datum voor vandaag in, bijvoorbeeld 25-11-1990';
    }

    return null;
  },
  dateInTheFuture(value) {
    if (!value) return null;

    const d = answerToDate(value);
    const now = new Date();

    if (!isValidDate(d) || !isAfter(d, now)) {
      const currentYearPlus5 = now.getFullYear() + 5;
      return `Vul een datum na vandaag in, bijvoorbeeld 25-11-${currentYearPlus5}`;
    }

    return null;
  },
  password(value) {
    if (!value) return null;

    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9_]).{6,}$/i;

    if (!regex.test(value)) {
      return 'Het wachtwoord voldoet niet aan de gestelde eisen';
    }
  },

};

export default validation;
