import axios from 'axios';
import { formParser as modelParser } from 'icatt-vue-forms';

const getInitialState = () => ({
  submitted: false,
  error: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset: (state) => { Object.assign(state, getInitialState()); },

    setError(state, error) {
      state.error = error.errors;
    },

    setSubmitted(state, submitted) {
      state.submitted = submitted;
    },

  },

  actions: {

    reset({
      state, rootState, commit, dispatch,
    }, form) {
      modelParser.submit(form, (data) => {
        commit('incrementLoading', true, { root: true });

        axios.post('/api/ResetPassword', data)
          .then((response) => {
            commit('setSubmitted', true);
          })
          .catch((error) => {
            console.log('resetpassword', error);
            commit('setError', error.response.data);
          })
          .finally(() => {
            commit('decrementLoading', true, { root: true });
          });
      });
    },

  },
  getters: {},
};
