import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/** @param {Promise<unknown>} promise */
const handleChunkError = (promise) => promise.catch(() => document.location.reload());

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // let op, alle paginas waarvan de name begint met 'public' zijn openbaar. dat wordt in de main.js geregeld
  routes: [

    {
      path: '/',
      name: 'home',
      component: () => handleChunkError(import(/* webpackChunkName: "ProfileView" */ './views/ProfileView.vue')),
    },
    {
      path: '/profiel',
      name: 'profiel',
      component: () => handleChunkError(import(/* webpackChunkName: "ProfileView" */ './views/ProfileView.vue')),
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => handleChunkError(import(/* webpackChunkName: "profileView" */ './views/ProfileView.vue'))
    }, {
      path: '/declaraties',
      name: 'declaraties',
      component: () => handleChunkError(import(/* webpackChunkName: "DeclaratiesView" */ './views/DeclaratiesView.vue')),
      meta: { right: 'GetDeclaraties' },
    }, {
      path: '/declaraties/declareren/:pleegouderid?',
      name: 'declareren',
      component: () => handleChunkError(import(/* webpackChunkName: "DeclarerenView" */ './views/DeclarerenView.vue')),
    },
    {
      path: '/declaraties/declarerennamens',
      name: 'declarerennamens',
      component: () => handleChunkError(import(/* webpackChunkName: "DeclarerenNamensView" */ './views/Declareren/DeclarerenNamensView.vue')),
      meta: { right: 'DeclarerenNamens' },
    },
    {
      path: '/declaraties/:id',
      name: 'declaratie',
      component: () => handleChunkError(import(/* webpackChunkName: "DeclaratieView" */ './views/DeclaratieView.vue')),
    },
    {
      // laten staan: deze route komt nog voor in oude Final exports
      path: '/declaratie/:id',
      name: 'declaratie_oud',
      component: () => handleChunkError(import(/* webpackChunkName: "DeclaratieView" */ './views/DeclaratieView.vue')),
    },
    {
      path: '/login',
      name: 'login',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "Login" */ './views/Account/Login.vue')),
    },
    {
      path: '/login/mfa/setup',
      name: 'mfaSetup',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "MfaAppView" */ './views/Account/MfaAppView.vue')),
    },
    {
      path: '/login/mfa',
      name: 'mfa',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "MfaView" */ './views/Account/MfaView.vue')),
    },
    {
      path: '/accounts/registreer',
      name: 'registreer',
      component: () => handleChunkError(import(/* webpackChunkName: "Registreren" */ './views/Account/Registreren.vue')),
    },
    {
      path: '/emailbevestiging',
      name: 'emailbevestiging',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "BevestigEmail" */ './views/Account/BevestigEmail.vue')),
    },
    {
      path: '/thankyou',
      name: 'thankYou',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "ThankYou" */ './views/Account/ThankYou.vue')),
    },
    {
      path: '/pleegkinderen',
      name: 'pleegkinderen',
      component: () => handleChunkError(import(/* webpackChunkName: "PleegkinderenView" */ './views/PleegkinderenView.vue')),
    },
    {
      path: '/wachtwoordvergeten',
      name: 'wachtwoordvergeten',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "WachtwoordVergetenView" */ './views/Account/WachtwoordVergetenView.vue')),
    },
    {
      path: '/wachtwoordwijzigen',
      name: 'wachtwoordwijzigen',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "ResetWachtwoordView" */ './views/Account/ResetWachtwoordView.vue')),
    },
    {
      // let op, deze pagina is publiek toegankelijk omdat de name begint met 'public'
      path: '/aanmeldingpleegouders',
      name: 'publicAspirantPleegoudersAanmeldenView',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "AspirantPleegoudersAanmeldenView" */ './views/AspirantPleegouders/AanmeldenView.vue')),
    },
    {
      path: '/contactformulier',
      name: 'publicContactformulier',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "ContactformulierView" */ './views/ContactFormulierView.vue')),
    },
    {
      path: '/aanmeldenjeugdige',
      name: 'publicAanmeldenJeugdige',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "AanmeldenJeugdigeForm" */ './views/AanmeldenJeugdigeView.vue')),
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: () => handleChunkError(import(/* webpackChunkName: "Accounts" */ './views/Account/AccountsView.vue')),
    },
    {
      path: '/accounts/:id',
      name: 'account',
      component: () => handleChunkError(import(/* webpackChunkName: "Account" */ './views/Account/AccountView.vue')),
    },
    {
      path: '/export',
      name: 'export',
      component: () => handleChunkError(import(/* webpackChunkName: "Export" */ './views/ExportView.vue')),
    },
    {
      path: '/vermogensbeheer',
      name: 'vermogensbeheer',
      component: () => handleChunkError(import(/* webpackChunkName: "Vermogensbeheer" */ './views/Vermogensbeheer/VerzoekenView.vue')),
      meta: { right: 'GetVermogensbeheerVerzoek' },
    },
    {
      path: '/intakebemiddeling',
      name: 'intakebemiddeling',
      component: () => handleChunkError(import(/* webpackChunkName: "IntakeBemiddeling" */ './views/IntakeBemiddeling/VerzoekenView.vue')),
      meta: { right: 'GetAllIntakeBemiddelingVerzoeken' },
    },
    {
      path: '/informatieverzoeken',
      name: 'informatieverzoeken',
      component: () => handleChunkError(import(/* webpackChunkName: "InformatieverzoekenView" */ './views/Informatieverzoeken/InformatieverzoekenView.vue')),
      meta: { right: 'GetAllInformatieverzoeken' },
    },
    {
      path: '/informatieverzoeken/:informatieverzoek_id',
      name: 'informatieverzoek',
      component: () => handleChunkError(import(/* webpackChunkName: "Informatieverzoek" */ './views/Informatieverzoeken/InformatieverzoekView.vue')),
      meta: { right: 'GetAllInformatieverzoeken' },
    },
    {
      path: '/klachten',
      name: 'klachten',
      component: () => handleChunkError(import(/* webpackChunkName: "KlachtenView" */ './views/Klachten/KlachtenView.vue')),
      meta: { right: 'GetAllKlachten' },
    },
    {
      path: '/vermogensbeheer/checklist/:id',
      name: 'vermogensbeheerChecklist',
      component: () => handleChunkError(import(/* webpackChunkName: "Vermogensbeheer" */ './views/Vermogensbeheer/VerzoekChecklistView.vue')),
    },
    {
      path: '/jeugdigen',
      name: 'jeugdigen',
      component: () => handleChunkError(import(/* webpackChunkName: "Jeugdigen" */ './views/Jeugdigen/JeugdigenView.vue')),
      meta: { right: 'GetAllJeugdigen' },
    },
    {
      path: '/jeugdigen/manage/:jeugdige_id?',
      name: 'manageJeugdige',
      component: () => handleChunkError(import(/* webpackChunkName: "ManageJeugdige" */ './views/Jeugdigen/ManageJeugdigeView.vue')),
      meta: { right: 'GetAllJeugdigen' },
    },
    {
      path: '/jeugdigen/:jeugdige_id',
      name: 'jeugdige',
      component: () => handleChunkError(import(/* webpackChunkName: "Jeugdige" */ './views/Jeugdigen/JeugdigeView.vue')),
      meta: { right: 'GetAllJeugdigen' },
    },
    {
      path: '/rekeningaanvragenoverzicht',
      name: 'rekeningaanvragenoverzicht',
      component: () => handleChunkError(import(/* webpackChunkName: "RekeningAanvragen" */ './views/RekeningAanvragen/RekeningAanvragenOverzichtView.vue')),
      meta: { right: 'GetRekeningaanvraag' },
    },
    {
      path: '/accounts/:jeugdige_id/rekening-aanvragen',
      name: 'rekening-aanvraag',
      component: () => handleChunkError(import(/* webpackChunkName: "RekeningAanvragen" */ './views/RekeningAanvragen/RekeningAanvragenAanvullenView.vue')),
      meta: { right: 'GetAllJeugdigen' },
    },
    {
      path: '/accounts/:jeugdige_id/rekening-aanvragen-checklist/:aanvraag_id/',
      name: 'rekening-aanvraag-checklist-jeugdige',
      component: () => handleChunkError(import(/* webpackChunkName: "RekeningAanvragen" */ './views/RekeningAanvragen/RekeningAanvragenChecklistView.vue')),
      meta: { right: 'GetAllJeugdigen' },
    },
    {
      path: '/rekening-aanvragen-checklist/:aanvraag_id/',
      name: 'rekening-aanvraag-checklist',
      component: () => handleChunkError(import(/* webpackChunkName: "RekeningAanvragen" */ './views/RekeningAanvragen/RekeningAanvragenChecklistView.vue')),
      meta: { right: 'GetAllJeugdigen' },
    },
    {
      path: '/deeltijdpleegzorg',
      name: 'deeltijdpleegzorg',
      component: () => handleChunkError(import(/* webpackChunkName: "DeclarerenView" */ './views/DeeltijdpleegzorgView.vue')),
      meta: { right: 'GetDeclaraties' },
    },
    {
      path: '/opvoedondersteuning',
      name: 'publicOpvoedondersteuning',
      meta: { public: true },
      component: () => handleChunkError(import(/* webpackChunkName: "AanmeldenOpvoedondersteuningForm" */ './views/OpvoedondersteuningView.vue')),
    },
    {
      path: '/bankgegevens',
      name: 'bankgegevens',
      meta: { right: 'GetAllBankgegevens' },
      component: () => handleChunkError(import(/* webpackChunkName: "Bankgegevens" */ './views/Bankgegevens.vue')),
    },
    {
      path: '/declaratietarievenbeheer',
      name: 'declaratietarievenbeheer',
      meta: { right: 'GetDeclaratieTarievenSets' },
      component: () => handleChunkError(import(/* webpackChunkName: "DeclaratieBeheer" */ './views/DeclaratieTarievenBeheerView.vue')),
    },
    {
      path: '/kitchensink',
      name: 'kitchensink',
      component: () => handleChunkError(import(/* webpackChunkName: "Kitchensink" */ './views/KitchensinkView.vue')),
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      meta: { public: true },
      beforeEnter() {
        window.location.href = '/unauthorized.html';
      },
    },
    {
      path: '/*',
      name: 'pagenotfound',
      meta: { public: true },
      beforeEnter() {
        window.location.href = '/404.html';
      },
    },

  ],
});
