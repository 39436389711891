<template>
  <div v-if="isTestEnvironment" :class="(forcePopup ? 'force-popup ' : '') + 'environment-sticker'">
    <button type="button" aria-haspopup="true">
      Testomgeving
    </button>
    <span>
      <button v-if="forcePopup" type="button" @click="forcePopup = false">
        <svg viewPort="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="11" x2="11" y2="1" stroke-width="2" />
          <line x1="1" y1="1" x2="11" y2="11" stroke-width="2" />
        </svg>
      </button>
      <div v-html="popup" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'EnvironmentSticker',
  data: () => ({
    isTestEnvironment: false,
    forcePopup: false,
    popup: 'Let op! Dit is de testomgeving van de Financiële Servicedesk.',
  }),
  mounted() {
    const { hostname } = location;
    if (hostname.indexOf('accept.icatt') != -1 || hostname.indexOf('localhost') != -1) {
      this.isTestEnvironment = true;

      this.forcePopup = true;
      setTimeout(() => {
        this.forcePopup = false;
      }, 5000);
    }
  },
};
</script>

<style scoped lang="scss">
    .environment-sticker {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 999;
        padding-top: 10px;

        a {
            color: color(green) !important;
        }

        span {
            width: 300px;
            font-size: 16px;
            max-width: 90vw;
            position: absolute;
            bottom: calc(100%);
            right: 0;
            transform: scale(.8);
            opacity: 0;
            pointer-events: none;
            transition: transform .3s, opacity .3s;
            transform-origin: bottom right;
                background-color: #000;
            color: #FFF;
            padding: 10px 15px;
            padding-right: 20px;

            > button {
                position: absolute;
                bottom: 100%;
                right: 0;
                background: none;
                font-size: 15px;
                text-decoration: underline;
                text-transform: uppercase;
                color: inherit;

                &:focus,
                &:hover {
                    svg {
                        transform: rotate(90deg) scale(1.2);
                    }
                }

                svg {
                    height: 12px;
                    width: 12px;
                    stroke: #333;
                    fill: #FFF;
                    transition: transform .3s;
                }
            }
        }

        button {
            cursor: pointer;
            transition: background-color .3s;
            border: none;
            outline: none;
        }

        > button {
            background-color: color(green);
            color: #FFF;
            border: none;
            font-size: 18px;
            padding: 15px 20px;
            font-weight: 600;
        }

        > button:focus,
        &:hover > button,
        &.force-popup > button {
            background-color: darken(color(green), 5%);

            + span {
                pointer-events: auto;
                opacity: 1;
                transform: scale(1);
            }
        }
    }
</style>
