function answerToDate(date) {
  const arr = date.split('-');
  const d = parseInt(arr[0]);
  const m = parseInt(arr[1] - 1);
  const y = parseInt(arr[2]);

  return new Date(y, m, d);
}

export default answerToDate;
